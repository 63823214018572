import React from 'react';

class Index extends React.Component {

    render() {
        let { vendor } = this.props;
        return (
            <div style={{
                padding: 10
            }}>
                <p style={{
                    fontWeight: 600
                }}>Vendor Details</p>
                <p style={{
                    fontSize: 14,
                    marginTop: 5
                }}>{vendor.name}</p>

                <p>
                    {vendor.address ? vendor.address.address : '-'}
                </p>

                <p>
                    {vendor.address ? vendor.address.city : '-'}
                </p>
                <p>
                    {vendor.address ? vendor.address.pincode : '-'}
                </p>

                <p>
                    Email: {vendor.email ? vendor.email : '-'}
                </p>
                {vendor.currency === 'Myr' ?
                    <>
                        <p>
                            SST: {vendor.gstin ? vendor.gstin : '-'}
                        </p>
                        <p>
                            TIN: {vendor.pan ? vendor.pan : '-'}
                        </p>
                    </>
                    : <>
                        <p>
                            Gstin: {vendor.gstin ? vendor.gstin : '-'}
                        </p>
                        <p>
                            Pan: {vendor.pan ? vendor.pan : '-'}
                        </p>
                    </>
                }
            </div>
        )
    }
}

export default Index;