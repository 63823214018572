import React, { useEffect, useRef, useState} from 'react'
import TabSwtich from "../../../../Common/fTabSwitch";
import  axios, {  get, post} from "../../../../../Model/Network/Config/Axios";
import {FloatInputButton} from "../../../../Common/fInputs";
import PhotoTile from "../../../../Common/fPhoto";
import Track from "../../../../Common/fTrack";
import Moment from "moment";
import {handleError, showToast} from "../../../../Common/fToast/ToastCallback";
import {SidePane} from "../../../../Common/fSidePane/RightPane";
import Invoice from "./fInvoice";
import Tds from './Tds';
import { numberWithCommas } from '../../../../../Utils/NumberToComma';
import { PageLoader } from '../../../../Common/Components/PageLoader';
import { ViewAttachment } from '../../../../Common/ViewAttachments';
import { DropdownModal } from '../../../../Common/fModal/DropdownModal';
import { timelineLogStatus } from '../../../fVendor/fProcurement/fPoAmend/GRN/GrnView/Timeline';
import CustomTags from '../../../fCommon/CustomTags/CustomTags'
import { Form } from '../../../../Common/Class/Form';
import { StateButton } from '../../../../Common/fStateButton';
import _ from 'lodash'
import { Integration } from '../Integration';
import { useSelector } from 'react-redux';
import SubmitButton from './submitButton';
import { SearchableSelect } from '../../../../Common/Class/Select/SearchableSelec2';
import { getBaseUrl } from '../../../../../Model/Network/Config/DecentralizeWrapper';
import { Form2 } from '../../../../Common/Class/Form2';

export  default function India(props) {
	let {details,onReload,id,debitNote,debitNoteDetails,isIndependentCreditNote} = props;
	const [getTab, setTab] = useState(1);
	const [Settlements] = useState(undefined);
	const [loading]  = useState(false);
	const [attachmentMode,setAttachmentMode] = useState(false);
	const [decline,setDecline] = useState(undefined);
	const [btnLoading,setBtnLoading] = useState(false);
	const [show,setShow]=useState(false);
	const {tenant,plugins} = useSelector(state=>state.auth.config)
	const {tdsAtLineItem} = plugins;
	const [selectedVendorAddress, setSelectedVendorAddress] = useState('');
	const [selectedBillAddress,setSelectedBillAddress] = useState({});
	const [selectedShipAddress,setSelectedShipAddress] = useState({});
	const [getVendors, setVendors] = useState([]);
	const [getVendor, setVendor] = useState({});
	const [vendorAddressFilter, setVendorAddressFilter] = useState([]);
	const [vendorAddress, setVendorAddress] = useState([]);
	const [toggleAddress, setToggleAddress] = useState(undefined);
	const [toggleBillAddress, setToggleBillAddress] = useState(undefined);
	const [toggleShipAddress, setToggleShipAddress] = useState(undefined);
	const [getOffices, setOffices] = useState([]);
	const [formData,setFormData] = useState()
	const [tdsSlabs,setTdsSlabs] = useState([]);

	const handleAddressChange=()=>{
		let billingGst=(selectedBillAddress && selectedBillAddress.gstin)?selectedBillAddress.gstin:"";
		let VendorGst=(selectedVendorAddress && selectedVendorAddress.des)?selectedVendorAddress.des:"";
        let cgst = 0,igst= 0,tax = 0;
		if(details && details.taxInfo && details.taxInfo.gst){
			tax=details.taxInfo.gst;
		}
		else if(details && details.items){
			details.items.forEach(item=>{
				tax += parseFloat((item && item.tax)?item.tax:0);
			})
		}

        if(VendorGst && VendorGst.length>2 && billingGst && billingGst && billingGst.length>2){
            if(VendorGst.slice(0,2)===billingGst.slice(0,2)){
                cgst = tax/2;
            }else{
                igst = tax;
            }
        }else{
            cgst = tax/2;
        }

		let key="";
		let value="";
		if(cgst){
			key="cgst"
			value=String(cgst)
		}
		else{
			key="igst"
			value=String(igst)
		}

		axios.put(`${getBaseUrl()}/vendor/vendor/creditNote/${details.id}/update`, { key,value}).then(() => {
			onReload();
			showToast({
				type: 'success',
				message: 'Field Updated'
			})
		}).catch((err) => {
			handleError(err);
			onReload()
		})
    }

	
	let FORM = useRef(),LINEITEMFORM = useRef([]),CNFORM = useRef();

	useEffect(()=>{
		setSelectedBillAddress({label:(details && details.officeData && details.officeData.name)?details.officeData.name:"",gstin:(details && details.officeData && details.officeData.gstin)?details.officeData.gstin:""})
		setSelectedShipAddress({label:(details && details.shipToData && details.shipToData.name)?details.shipToData.name:"",gstin:(details && details.shipToData && details.shipToData.gstin)?details.shipToData.gstin:""})
		setVendor({label:(details && details.vendor && details.vendor.name)?details.vendor.name:(details && details.vendor && details.vendor.tradeName)?details.vendor.tradeName:""})
	},[details])

	useEffect(()=>{
		if(details.status === 'APPROVAL'){
			FORM.current.loadForm(`admin::creditNote::category::${details.categoryId}`)
		}
	},[details.status])

	useEffect(()=>{
		if(details.vendor.id)
		getVendorDetails()
	},[details.vendor])

	useEffect(()=>{
		// loadform once initialvalue of form has been fetched
		if(formData){
			CNFORM.current.loadForm(`credit_note::category::${details.categoryId}`);
		}
	},[formData])

	useEffect(()=>{
		if(details.status === 'APPROVAL' && details.attrs && isIndependentCreditNote && CNFORM && CNFORM.current){
			get(`/forms/credit_note::category::${details.categoryId}`, (e, r) => {
				if (r) {
					getFormData(r.forms);
				}else{
					handleError(e);
				}
			})
		}
	}, [details, isIndependentCreditNote])

	//set initialvalue of header level custom form
	const getFormData = (forms) => {
		let formObj = {};
		forms.forEach(el => {
			formObj[el.fieldId] = details.attrs[el.fieldId];
		})
		setFormData(formObj)
	}

	useEffect(()=>{
		if(details.status === 'APPROVAL'){
			details.items.forEach((el,index) => {
				LINEITEMFORM.current[index].loadForm(`finance::creditNote::item::${details.categoryId}`);
			})
		}
	},[details.items, details.status])

	const mapLineItemTag = (item,index) =>{
		return(
			<div key={index} style={{border:'1px solid var(--border-color)',padding:12,borderRadius:12,marginTop:20,paddingTop:0}}>
				<div style={{display: 'flex', alignItems: 'center'}}>
					<FloatInputButton disabled={true} style={{width: '100%'}}  title={item.key} placeholder={item.value}/>
				</div>
			</div>
		)
	}

	const getVendorDetails = () => {
		let vendorId = details.vendor.id
		get(`vendor/contacts/${vendorId}/details`, (e, r) => {
			if (r) {
				let findDefault = r.addresses.filter((item)=>{return item && item.id}).find((e) => details && details.vendor && details.vendor.address && details.vendor.address.id && e.id === details.vendor.address.id)
				let addresses = r.addresses.filter((item)=>{return item && item.id}).map(item => ({
					value: item.id,
					label: item.address,
					des: item.gstin
				}))
				setVendorAddress(addresses);
				setVendorAddressFilter(addresses);
				// setVendorStatus(r.vendorStatus);
				if (findDefault) {
					setSelectedVendorAddress({
						value: findDefault.id,
						label: findDefault.address,
						des: findDefault.gstin
					});
				}
				if(r && r.config && r.config.tdsMasterIds){
					setTdsSlabs(r.config.tdsMasterIds)
				}
			} else {
			}
		})
	}

	const updateCreditNote = (field, value) => {
		let baseUrl = getBaseUrl();
		let key = "";
		if(baseUrl.endsWith('/')) baseUrl = baseUrl.slice(0,-1);
		switch (field) {
			case 'vendor':
				
				post(`vendor/vendor/creditNote/${details.id}/vendor.change`,{vendor: getVendor.value},(e,r)=>{
					if(r){
						setVendor('');
						onReload()
						showToast({
							type: 'success',
							message: 'Vendor Updated'
						})
					}else{
						handleError(e);
					}
				})
				return;
			case 'vendorAddress':
				axios.put(`${baseUrl}/vendor/vendor/creditNote/${details.id}/update`, { key: field, value: String(selectedVendorAddress.value) }).then(() => {
					setToggleAddress(undefined);
					showToast({
						type: 'success',
						message: 'Vendor address Updated'
					})
					handleAddressChange()
				}).catch((err) => {
					handleError(err);
				})
				return;
			case 'billing':
				axios.put(`${baseUrl}/vendor/vendor/creditNote/${details.id}/update`, { key: field, value: String(selectedBillAddress.value) }).then(() => {
					setToggleBillAddress(undefined);
					showToast({
						type: 'success',
						message: 'Billing Address Updated'
					})
					handleAddressChange()
				}).catch((err) => {
					handleError(err);
				})
				return;
			case 'shipping':
				axios.put(`${baseUrl}/vendor/vendor/creditNote/${details.id}/update`, { key: field, value: String(selectedShipAddress.value) }).then(() => {
					onReload()
					setToggleShipAddress(undefined);
					showToast({
						type: 'success',
						message: 'Shipping Address Updated'
					})
				}).catch((err) => {
					handleError(err);
				})
				return;
			case 'date':
				axios.put(`${baseUrl}/vendor/vendor/creditNote/${details.id}/update`, { key: "date",value: Moment(value, "YYYY-MM-DD").format("DD-MM-YYYY") }).then(() => {
					onReload()
					showToast({
						type: 'success',
						message: 'Shipping Address Updated'
					})
				}).catch((err) => {
					handleError(err);
				})
				return;
			case 'billNO':
				key='billNo'
				break;
			case 'base':
				key = 'base';
				break;
			case 'sgst':
				key = 'sgst';
				break;
			case 'igst':
				key = 'igst';
				break;
			case 'cgst':
				key = 'cgst';
				break;
			default:
				return
		}

		axios.put(`${baseUrl}/vendor/vendor/creditNote/${details.id}/update`, { key,value}).then(() => {
			onReload();
			showToast({
				type: 'success',
				message: 'Field Updated'
			})
		}).catch((err) => {
			handleError(err);
		})
	}


	
	let tabs = [
		{
			name: 'Format', active: getTab === 1 , onClick: () => {
				setTab(1)
			}
		},
		{
			name: 'Insights', active: getTab === 4 , onClick: () => {
				setTab(4)
			}
		},
		...(details.config && details.config &&  _.size(details.config.integrationData)>0)  ?
		[
			{
				name: 'Integration', active: getTab === 5, onClick: () => {
					setTab(5)
				}
			}
		] : [],
		{
			name: 'Timeline', active: getTab === 3 , onClick: () => {
				setTab(3)
			}
		},
	]


	if(loading) {
		return <PageLoader />
	}

	return (
		<SidePane button={false} onClose={() => props.onClose(false)}
				  column={
					  <Invoice 
						  vendor={(details && details.vendor)?details.vendor:undefined}
						  tdsSlabs={tdsSlabs}
						  reload={onReload} CreditNoteId={details.id} data={{
						  vendor: details.vendor.name,
						  tradeName:details.vendor.tradeName,
						  vendor_code:details.vendor.registeredId,
						  email: details.vendor.email,
						  rcVendor:details.vendor.rcVendor,
						  vendor_id: details.vendor.gstin,
						  vendorGstin:(details && details.vendor && details.vendor.address && details.vendor.address.gstin)?details.vendor.address.gstin:"",
						  vendorAddress: details.vendor.address,
						  vendorID: details.vendor.id,
						  date: details.date,
						  due: details.dueOn?details.dueOn:undefined,
						  Credit_note_id: details.creditNoteId,
						  bill_add: details.officeData.address,
						  ship_add: details.shipToData.address,
						  bill_to:details.officeData,
						  ship_to:details.shipToData,
						  invoice_id:(details && details.invoice && details.invoice.invoiceId)? details.invoice.invoiceId:undefined,
						  bill_number:details.billNo,
						  debitNote,
						  debitNoteDetails,
						  table: [
							  ...details.items.map((item => {
								  return ({
									  id: item.id,
									  referenceId:item.referenceId,
									  desc: item.name?item.name:item.service,
									  service:item.service,
									  price: item.price,
									  original_qtty:item.qtty,
									  credit_note_qtty: item.qtty,
									  qtty: item.qtty,
									  base_amt: item.base,
									  gross_amt: item.base - (item.base * (details.taxInfo.discount/100)),
									  tax_amt: item.tax,
									  amount: item.amount,
									  hsn: item.hsn,
									  cessAmount:item.cessAmount?item.cessAmount:0,
									  debitQty:item.debitQty?item.debitQty:0,
									  forms:item.data,
									  taxSlab:item.taxSlab,
									  unit: item.unit,
									  glCode: (item && item.glCode)?item.glCode:"",
									  tds: (item.tdsAmount || item.tdsRate) && {
										tdsAmount: item.tdsAmount ? item.tdsAmount : "-",
										tdsCode: item.tdsCode,
										tdsRate: item.tdsRate,
										tdsDesc: item.tdsDesc,
									},
								  })
							  }))
						  ],
						  cgst:details.taxInfo.cgst,
						  gst: details.taxInfo.gst,
						  sgst: details.taxInfo.sgst,
						  igst: details.taxInfo.igst,
						  tds: details.taxInfo.tds,
						  total_tax: details.taxInfo.gst,
						  total_base: details.taxInfo.baseAmount,
						  roundOff:details.taxInfo.roundOff,
						  total_amt: details.taxInfo.total,
						  payable_amt: details.taxInfo.paidAmount,
						  discount:details.taxInfo.discount || 0,
						  discountAmount:details.taxInfo.discountAmount ||0,
						  cessAmount:details.taxInfo.cessAmount,
						  status:details.status,
						  settlement:Settlements?Settlements.settled:true,
						  allSettlement:Settlements?Settlements.settlements:[],
						  mSettlement:Settlements?Settlements:false,
						  invoice_amount:details.invoice ? details.invoice.amount :undefined,
						  penalty: details.config && details.config.penalty ?  details.config.penalty : 0,
						  category: details.category,
						  categoryId: details.categoryId,
						  tdsAtLineItem: tdsAtLineItem,
						  isIndependentCreditNote:isIndependentCreditNote
					  }} 
					 
					  
					  />
				  }>
			<div className='FadeRight-Effect' style={{fontSize: '12px', width: '100%', minWidth: '400px'}}>
				<div style={{display:'flex',alignItems:'center',marginBottom:35}}>
					<div style={{flex:2}}>
						<div style={{fontWeight: 600, fontSize: '18px', lineHeight: '1.3'}}>Credit Note</div>
						<div style={{
							color: 'var(--text-color)',
							fontWeight: 600,
							fontSize: '24px',
							lineHeight: '1.3'
						}}>{details.creditNoteId}</div>
					</div>
					{details.config.files.length?
					<div style={{flex:1,display:'flex',justifyContent:'flex-end'}}>		
						 <div onClick={()=>setAttachmentMode(!attachmentMode)} style={{background:'var(--primary-color)',cursor:'pointer',color:'#fff',padding:'5px 10px',borderRadius:4,...attachmentMode?{background:'#000'}:{}}}>{attachmentMode?'Disable View': 'Image View'} </div>			
					</div>
					:null}
				</div>
				{
					
				attachmentMode ?
				<ViewAttachment items={details.config.files}  />
				:
				<>
				<TabSwtich tabs={tabs}/>

				{getTab === 1 ?
					<div id="Invoice_Format" className='FadeRight-Effect' style={{position:'relative',paddingBottom:100}}>
						{
							details.status==='POHOLD'?
							<div style={{fontSize: '12px',background: '#FDF0DD', borderRadius: '6px', marginTop: '20px', padding: '20px', width: '100%', display: 'flex', alignItems: 'center'}}>
								<svg style={{marginRight:10, fontSize: '16px'}} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
									<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
									<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
								</svg>
								<span>This Invoice is on hold because <b>PO is active or GRN is pending</b>.</span>
							</div>
							:<></>
						}
				
					{
                        details.duplicates && details.duplicates.length  ?
                        <>
                            <div style={{fontSize: '14px', fontWeight: 'bold',margin: '30px 0px 15px 0px'}}>Duplicate Voucher Id</div>
                            {details.duplicates.map((item)=>{
                                    return (
                                        <div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>
                                <svg style={{fontSize: '14px', marginRight: '10px', verticalAlign: 'middle', color: '#fd7e14', marginBottom: '2px'}} className="bi bi-exclamation-triangle-fill" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                                </svg>
                                {item}</span>
                                        </div>
                                    )
                                })}
                        </>
                        : null
                    }

						<div style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>Details
						</div>
						<div style={{marginTop: '5px'}}><span
							style={{color: 'var(--text-color)', marginRight: '3px'}}>Credit Note ID:</span>{details.creditNoteId}
						</div>
						<div style={{marginTop: '5px'}}><span
							style={{color: 'var(--text-color)', marginRight: '3px'}}>Category:</span> {details.category}</div>
						<div style={{marginTop: '5px'}}><span style={{
							color: 'var(--text-color)',
							marginRight: '3px'
						}}>Amount:</span> {`INR ${numberWithCommas(details.amount)}`}</div>

					
						
						{
							props.status === 'COMPLETED' || props.status === 'CANCELLED' ?
								<>
									<div style={{
										fontSize: '14px',
										fontWeight: 'bold',
										margin: '30px 0px 15px 0px'
									}}>Information
									</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>Vendor Name:</span> {details.vendor.name}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>Invoice Date:</span> {Moment(details.date).format('DD-MM-YYYY')}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>Invoice ID:</span> {details.creditNoteId}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>GSTIN:</span> {details.vendor.gstin}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>Email:</span> {details.vendor.email}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>Billing Address:</span> {details.officeData.address}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>Shipping Address:</span> {details.officeData.address}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>GST:</span> {details.taxInfo.gst}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>SGST/UTGST:</span> {details.taxInfo.sgst + details.taxInfo.utgst}
									</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>CGST:</span> {details.taxInfo.cgst}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>IGST:</span> {details.taxInfo.igst}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>TDS:</span> {details.taxInfo.tds}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>Base Amount:</span> {details.taxInfo.baseAmount}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>Payable Amount:</span> {details.taxInfo.paidAmount}</div>
								</>
								: <>
									<div style={{
										fontSize: '14px',
										fontWeight: 'bold',
										margin: '30px 0px 15px 0px'
									}}>Original Invoice Details
									</div>
									{
										(!isIndependentCreditNote) ? <></>:
											<div>
												<div style={{ display: 'flex', width: '100%', alignItems: 'end' }}>
													<SearchableSelect
														// disabled={details.status !== 'APPROVAL'}
														disabled={true}
														label="Vendor Name"
														placeholder="Select vendor"
														value={getVendor}
														reset={() => {
															setSelectedVendorAddress('');
														}}
														select={vendorModal => {
															setVendor(vendorModal)
														}}
														add={{
															name: "Add New Vendor",
															link: "/app/vendor/contacts/new"
														}}
														onChange={val => {
															if (val.length > 1) {
																get(`/vendor/contacts/search?q=${val}`, (e, r) => {
																	setVendors(r.contacts.map(item => ({
																		value: item.id,
																		label: item.title,
																		des: item.email
																	})))
																})
															} else {
																setVendors([])
															}
														}}
														items={getVendors} />
													{/* {
														getVendor === '' ? <></> :
															<SubmitButton onClick={() => updateCreditNote('vendor')} />
													} */}
												</div>

												<div style={{ display: 'flex', width: '100%', alignItems: 'end' }}>
													<SearchableSelect
														label="Vendor Address"
														disabled={details.status !== 'APPROVAL'}
														placeholder="Select address"
														value={selectedVendorAddress}
														reset={() => {
															setVendorAddressFilter(vendorAddress)
														}}
														select={vendorModal => {
															setToggleAddress(true)
															setSelectedVendorAddress(vendorModal)
														}}
														onChange={val => {
															if (val.length > 1) {
																setVendorAddressFilter(vendorAddress.filter((e) => JSON.stringify(e).toLowerCase().includes(val.toLowerCase())))
															} else {
																setVendorAddressFilter(vendorAddress)
															}
														}}
														items={vendorAddressFilter} />
													{
														(!toggleAddress) ? <></> :
														<SubmitButton onClick={() => {
															updateCreditNote('vendorAddress')
														}}/>
													}
												</div>

												<div style={{ display: 'flex', width: '100%', alignItems: 'end' }}>
													<SearchableSelect
														disabled={details.status !== 'APPROVAL'}
														label="Billing Address"
														placeholder="Select Office"
														value={selectedBillAddress}
														reset={() => {
															setOffices([])
														}}
														select={office => {
															setToggleBillAddress(true)
															setSelectedBillAddress(office)
														}}
														onChange={q => {
															if (q.length > 0) {
																let data = { q, p2p: "yes" };
																get(`/search/v1/offices`, (e, r) => {
																	if (r) {
																		setOffices(r.offices.map(item => ({
																			label: item.name,
																			value: item.id,
																			gstin : (item && item.gstin)?item.gstin:""
																		})))
																	}
																}, data)
															} else {
																setOffices([])
															}
														}}
														items={getOffices} />
													{
														!toggleBillAddress ? <></> :
														<SubmitButton onClick={()=>{
															updateCreditNote('billing')
														}} />
													}
												</div>

												<div style={{ display: 'flex', width: '100%', alignItems: 'end' }}>
													<SearchableSelect
														label="Shipping Address"
														disabled={details.status !== 'APPROVAL'}
														placeholder="Select Office"
														value={selectedShipAddress}
														reset={() => {
															setOffices([])
														}}
														select={office => {
															setToggleShipAddress(true)
															setSelectedShipAddress(office)
														}}
														onChange={q => {
															if (q.length > 0) {
																let data = { q, p2p: "yes" };
																get(`/search/v1/offices`, (e, r) => {
																	if (r) {
																		setOffices(r.offices.map(item => ({
																			label: item.name,
																			value: item.id,
																			gstin : (item && item.gstin)?item.gstin:""
																		})))
																	}
																}, data)
															} else {
																setOffices([])
															}
														}}
														items={getOffices} />
													{
														!toggleShipAddress ? <></> :
															<SubmitButton onClick={()=>{
																updateCreditNote('shipping')
															}} />
													}
												</div>
												<div style={{ width: '100%' }}>
													<FloatInputButton type="date" onSubmit={(val) => {
														updateCreditNote('date', val)
													}} title='Credit Note Date' disabled={details.status !== 'APPROVAL'} placeholder={Moment(details.date).format('YYYY-MM-DD')} />
												</div>

											</div>
									}
										


									<FloatInputButton  title='Credit Note Number' disabled={details.status !== 'APPROVAL'} placeholder={details.billNo} onSubmit={(val)=>{
										if (val.length > 16) {
											showToast({
												type: 'error',
												message: "ICredit Note Number cannot be greater than 16 characters"
											})
											return
										}
										updateCreditNote('billNO', val)
									}}/>

									<div style={{display: 'flex'}}>
										<div style={{width: '50%'}}><FloatInputButton  title='Base Amount' placeholder={details.taxInfo.baseAmount} disabled={details.status !== 'APPROVAL'} onSubmit={(val)=>updateCreditNote('base', val)}/>
										</div>
										{(!["bajajelec","BAJAJELECT"].includes(tenant) || ![4016].includes(details.categoryId)) && <div style={{width: '50%', paddingLeft: '10px',marginTop:20}}>
											<Tds dbId={details.id} id={id} value={details.taxInfo.tds} init={()=>{
												onReload()
											}} 			
											disabled={details.status!=='APPROVAL'}
											/>
										</div>}
									</div>
									<div style={{display: 'flex'}}>
										<div style={{width: '33%'}}><FloatInputButton  title='SGST / UTGST '
											placeholder={details.taxInfo.sgst?details.taxInfo.sgst.toFixed(2):details.taxInfo.sgst}
											disabled={true} onSubmit={(val)=>updateCreditNote('sgst', val)}
											/>
										</div>
										<div style={{width: '33%', paddingLeft: '10px'}}><FloatInputButton
											 title='IGST ' placeholder={details.taxInfo.igst?details.taxInfo.igst.toFixed(2):details.taxInfo.igst} disabled={true} onSubmit={(val)=>updateCreditNote('igst', val)}/></div>
										<div style={{width: '33%', paddingLeft: '10px'}}><FloatInputButton
											title='CGST' placeholder={details.taxInfo.cgst} disabled={true} onSubmit={(val)=>updateCreditNote('cgst', val)}/></div>
									</div>

								</>
						}
						<div
							style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 15px 0px'}}>Attachment
						</div>
						<div style={{
							display: 'flex',
							marginLeft: '-15px',
							flexWrap: 'wrap',
							maxHeight: '250px',
							overflowY: 'scroll'
						}}>

							{
								details.config.files.length > 0 ?
									details.config.files.map((item, index) => {
										return (
											<PhotoTile  key={`Photo-${index}`} link={item}/>
										)
									}) : <></>
							}
						</div>

						{details.status === 'APPROVAL' &&
							<>	
								{ (isIndependentCreditNote) && <Form2 ref={(ref) => CNFORM.current = ref} label="Credit Note Forms" initialValues={formData} dependentKeyValue={formData} updateForm={(item,val,name)=>{ 
									let baseUrl = getBaseUrl();
									if(baseUrl.endsWith('/')) baseUrl = baseUrl.slice(0,-1);
									axios.put(`${baseUrl}/vendor/vendor/creditNote/${details.id}/update`, { key: item, value: val }).then(() => {
										onReload()
										showToast({
											type: 'success',
											message: 'Custom Form Updated SucessFully'
										})
									}).catch((err) => {
										handleError(err);
									})
								}}/>}
								<Form handler={(ref) => (FORM.current = ref)} />
						
								{details.items.map((el,index) => {
									return(
										<Form label={`${el.name} custom form`} handler={(ref) => (LINEITEMFORM.current[index] = ref)} />
									)
								})}
							</>
						}	
						
						{
							details.status === 'APPROVAL' ?
							<div style={{left:'calc(100% - 650px)',right:0,bottom:0,background:'#fff',borderTop:'1px solid var(--border-color)',position:'fixed',height:55,width:'100%',display:'flex',alignItems:'center',paddingLeft:10,paddingRight:10}}>
								{btnLoading ?
								<div
									className="btn btn-portlet-sucess" style={{background:"#E4EFE7",color:'#198754',width:'100%',maxWidth:'315px'}}>
									<StateButton />
								</div>:
								<div onClick={()=>{
									setBtnLoading(true)
										let data = {};
										let flag = true;
										let count = 0;
										let internalCount = 0;
										// to get custome line field values
										LINEITEMFORM.current.forEach((ref) => {
											flag = false;
											ref.getKeyValue(val => {
												if (Object.keys(val).length) {
													count++;
												}
												flag = true;
								
											});
								
										})
								
										if (!flag) {
											showToast({
												type: 'error',
												message: "Please check line item custom first"
											})
											setBtnLoading()
											return
										}

										if (count > 0) {

											details.items.forEach((i, index) => {
												let formValue = {};
												LINEITEMFORM.current[index].getKeyValue(val => {
													formValue = { ...val }
												})
												post(`vendor/vendor/creditNote/finance.item.form/${details.id}`, { "itemId": i.id, "forms": { ...formValue } }, (e, r1) => {
													if (r1) {
														internalCount++;
														if (internalCount === count) {
								
															FORM.current.getKeyValue(val => {
																data.forms = { ...data.forms, ...val };
																post(`vendor/vendor/creditNote/approve/${details.id}`, data, (e, r) => {
																	if (r) {
																		setBtnLoading(false);
																		props.onReload();
																		props.init && props.init()
																		showToast({
																			type: 'success',
																			message: 'Approved'
																		})
																	} else {
																		setBtnLoading(false);
																		handleError(e)
																	}
																})
															})
								
														}
													} else {
														setBtnLoading(false);
													}
												})
											})
										}
										if (flag) {
											if (count === 0) {
												FORM.current.getKeyValue(val => {
													data.forms = { ...data.forms, ...val };
													post(`vendor/vendor/creditNote/approve/${details.id}`, data, (e, r) => {
														if (r) {
															setBtnLoading(false);
															props.onReload();
															props.init && props.init()
															showToast({
																type: 'success',
																message: 'Approved'
															})
														} else {
															setBtnLoading(false);
															handleError(e)
														}
													})
												});
											}
								
										} else {
											showToast({
												type: 'error',
												message: "Please check line item custom first"
											})
										}
								}}  className='btn btn-portlet-success' style={{background:"#E4EFE7",color:'#198754',width:'100%',maxWidth:'315px'}}>
								   Approve
								</div>
								}
								<div onClick={()=>{
									setDecline(details.id)
								}} className='btn btn-portlet-success' style={{background:"#ffdad4",color:'#ba1b1b',width:'100%',maxWidth:'315px',marginLeft:10}}>
								   Decline
								</div>
					   		</div>
							:null
						}
                      
                       
						
					</div> : <></>
				}
                       
						
				

				{getTab === 3 ?
					<div id="Invoice_Timeline" className='FadeRight-Effect'>
						<div style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>Timeline
						</div>
						<Track stages={
							[...details.timelines.map((log,index) => {

								let DESC = <div><b>{log.name}</b><br/>
									<div style={{marginTop: '5px'}}>Type: {log.type}</div>
									<div style={{marginTop: '5px'}}>Status: {log.status}</div>

									<div style={{marginTop: '5px'}}>Sent
										on: {Moment(log.time).format("hh:mm a DD MMM YY")}
										<br/>{log.actionTime && log.status !== 'PENDING' ? `Approved on: ${Moment(log.actionTime).format(" hh:mm a DD MMM YY")}` : ''}
									</div>
								</div>

								return (
									{
										title: log.email,
										desc: DESC,
										status: log.type === 'start' || log.type === 'scanner-end' || (log.type === 'scanner-start' && details.timelines.length>(index+1))? true : log.status ? timelineLogStatus.includes(log.status) : log.name === 'Finance Action' || log.type === 'Completed'|| log.name === 'COMPLETED' ? true : false
									})
							})]
						}/>
					</div> : <></>
				}

				{getTab === 4 ?
					<div id="Invoice_Insight" className='FadeRight-Effect'>
							<div
								style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>Insights
							</div>
							{
								details.data ? details.data.map(mapLineItemTag) : <></>
							}
							<div style={{border:'1px solid var(--border-color)',padding:12,borderRadius:12,marginTop:20,paddingTop:10}}>
								<div style={{'display':'flex','justifyContent':'space-between'}}>
									<div onClick={() => {setShow((!show))}} style={{fontSize: '14px', fontWeight: 'bold', cursor:'pointer'}}>Custom Tags</div>
									<div style={{'border':'1px solid black', 'borderRadius':'100%', width:'18px',height:'18px','display':'flex','justifyContent':'center', 'alignItems':'center',cursor:'pointer'}} onClick={() => {setShow((!show))}}>
										<img style={{width:12,transform:!show?'rotate(0deg)':'rotate(180deg)'}} src={require('./down.png')} alt="down"/>
									</div> 
								</div>
								{(show)?<div style={{marginLeft:'1rem'}}>
									<CustomTags title={"Vendor Custom Tags"} data={(details && details.vendor && details.vendor.customTags)?details.vendor.customTags:{}}/>
									<CustomTags title={"Office Data Custom Tags"} data={(details && details.officeData && details.officeData.customTags)?details.officeData.customTags:{}}/>
									<CustomTags title={"Ship To Data Custom Tags"} data={(details && details.shipToData && details.shipToData.customTags)?details.shipToData.customTags:{}}/>
								</div>:null}
							</div>
						</div> : <></>
				}

						{getTab === 5 ?
							  	details && details.config && details.config.integrationData && _.size(details.config.integrationData) >0 ?
									<Integration
										type="credit-note"
										reference={details.id}
										integrationData={details.config.integrationData}
										refresh={props.onReload}
									/>

									: null

								: null}
				<div style={{height: '50px'}}/>
				</>}
			</div>
			

			{
				(decline && decline!== 0) ? <DropdownModal
					title="Decline Credit Note"
					button="Decline"
					close={() => {
						setDecline(undefined)
					}}
					dropdown="vendor-decline"
					onNext={val => {
						post(`vendor/vendor/creditNote/decline/${decline}`,{remarks:val},(e,r)=>{
							if(r) {
								props.onReload();
								setDecline(undefined);
								props.init && props.init();
								showToast({
									type:'success',
									message:"Credit Note Decline Successfully"
								})
							}
						})
					}}
				/> : null
			}


		</SidePane>
	)

}
