import React, {useEffect, useRef, useState } from 'react'
import Moment from 'moment';
import {Link,useParams} from 'react-router-dom';
import { deleteApi, get, post } from '../../../../../../Model/Network/Config/Axios';
import { handleError, showToast } from '../../../../../Common/fToast/ToastCallback';
import {SearchOffice2} from '../../../../../Common/fSubmitInvoice/common/SearchOffice';
import { Modal } from '../../../../../Common/fModal/Modal';
import { FloatDate } from '../../../../../Common/Class/FloatLabelInput';
import { numberWithCommas } from '../../../../../../Utils/NumberToComma';
import RfqModal from "../../fCommon/RfqModal";
import { FunctionalInput } from '../../../../../Common/fInputs';
import { useSelector } from 'react-redux';
import { SearchPO } from '../../fCommon/SearchPO';
import AddConditions from './AddConditions';
import { Spinner } from '../../../../../Common/fLoader';
import ToggleSwitch from '../../../../../Common/fSwitch';
import { ReadOnly } from '../../../../../Common/fCard/ButtonOutline/ReadOnly';
import TabSwtich from '../../../../../Common/fTabSwitch';
import Owner from './ModalOwner/Owner';
import ConditionOwner from './ModalOwner/ConditionOwner';
import SelectGST from '../../../../../Common/fUtils/SelectGST';

//const NOTES_REGEX = /^[a-zA-Z0-9\s]+$/;
const OrderItems = (props) => {
	const [addressType,setAddressType] = useState(false);
	const [office,setOffice] = useState(undefined);
	const [edit,setEdit] = useState(false);
	const [update,setUpdate] = useState({});
    const [state,setState] = useState({
		conditions:false,
		showConditions: false,
		allConditions:[],
		loading:false,
		conditionSum:[],
		toggle:false,
		editItem:false
		
	})
	const [remove,setRemove] = useState(false);
	const [grnOwner,setGrnOwner] = useState(false);
	const [invoiceOwner,setInvoiceOwner] = useState(false);
	const [updateOwnerModal,setUpdateOwnerModal] = useState(false);
	const [updateConditionOwnerModal,setUpdateConditionOwnerModal] = useState(false);
	const {conditions,showConditions,allConditions,loading,conditionSum,toggle,editItem} = state;
	const [editProductTax,setEditProductTax] = useState();
	const [tab,setTab] = useState(1);
	const [lineItemId,setLineItemId]=useState(undefined);

	let {conditionalPo,optionalFields,ownerMappingAtPoItemLevel}= useSelector(state=>state.auth.config.plugins);
	let tabs = [{
			name: 'GRN', active: tab === 1, onClick: () => {
				setTab(1)
		}},{
			name: 'Invoice', active: tab === 2, onClick: () => {
				setTab(2)
		}}
	];

	let FREIGHTPO = useRef(),OTHERPO=useRef(),RAILPO=useRef(),HANDLINGPO=useRef();

	let {id} = useParams();
	let date=useRef();
	let {data} = props;
	let {procured,status,invoices,conditionalPos,vendor,billingTo} = data;
	let Amendments = true;
	const [rq,setRq] = useState(
		0
	);
	let QTY = useRef(),PRICE=useRef(),HSN=useRef(),NOTES=useRef(),REMARKS=useRef(),REMOVE_REMARKS=useRef()
	
	const excludeAttars = ['lineItemReferenceNo','autoBudgeting','SO Number','notes','budgeted_amount','budget_id','budget_failed_reason','budget_status']

	useEffect(()=>{
		if(edit) {
			QTY.current.value = edit.qtty;
			PRICE.current.value = edit.price;
			HSN.current.value = edit.hsn;
			NOTES.current.value = edit.notes;
			setEditProductTax({label:edit.taxSlab,value:edit.taxSlab})
		}
	},[edit])
	useEffect(()=>{
		if(props && props.data && props.data.procured && updateOwnerModal){
			setUpdateOwnerModal((prev)=>{
				return {
					index:prev.index,
					item:props.data.procured[prev.index]
				}
			})
		}
	},[props])

	useEffect(()=>{
		if(state && state.allConditions && updateConditionOwnerModal){
			setUpdateConditionOwnerModal((prev)=>{
				return {
					index:prev.index,
					item:state.allConditions[prev.index]
				}
			})
		}
	},[state])

	const getConditions = () =>{
		let mDeta = {}
		 let filters = []
		 filters.push(`${id}@t0.main_po_id`)
		  if(toggle) filters.push(`${vendor.id}@t0.vendor_id`)
	      mDeta.filter = filters
		get("/vendor/procurement/poCondition/getPoItemConditions", (e, r) => {
			if (r) {
				setState(prev => ({...prev,conditionSum:r.poConditions,loading:false}))
			} else {
				setState(prev => ({...prev,loading:false}))
			}
		},mDeta);
	}

	useEffect(()=>{
		getConditions()
	},[toggle])

	useEffect(()=>{
		if(edit && conditionalPo && status === 'DRAFT') {
			FREIGHTPO.current.setValue( conditionalPos[edit.id] ?conditionalPos[edit.id].freightPo:undefined);
			RAILPO.current.setValue(conditionalPos[edit.id]?conditionalPos[edit.id].railPo:undefined);
			HANDLINGPO.current.setValue(conditionalPos[edit.id]? conditionalPos[edit.id].handlingPo:undefined);
			OTHERPO.current.setValue(conditionalPos[edit.id] ? conditionalPos[edit.id].otherPo :undefined)
		}
	},[edit,status,conditionalPo])


	let total = 0,qty=0,count=0,base=0,cgst=0,sgst=0,igst=0,tax=0;

	procured.forEach(val=>{
		count +=1;
		total +=val.amount;
		qty +=val.qtty
		base += val.base;
		tax += val.tax
	})
	let cTotal = 0, cQty=0,cCount=0;

	if(!vendor.gstin){
		igst = tax;
	}
	else if (vendor && vendor.gstin && vendor.gstin.length > 2 && billingTo && billingTo.gstin && billingTo.gstin.length > 2) {
        if (vendor.gstin.slice(0, 2) === billingTo.gstin.slice(0, 2)) {
            cgst = tax / 2;
            sgst = tax / 2;
        } else {
            igst = tax;
        }
    } else {
        cgst = tax / 2;
        sgst = tax / 2;
    }

	if(conditionSum.length) {
		conditionSum.forEach(val=>{
			cCount +=1;
			cTotal +=val.price;
			cQty +=val.qty
		})
	}	

	const addCondition = (data) =>{
		post(`vendor/procurement/poCondition/addPoItemCondition`,data,(e,r)=>{
			if(r) {
					showToast({
						type:'success',
						message:"Line Item Update successfully"
					});
					props.init();
					setState(prev => ({...prev,conditions:false,showConditions:false}))
			} else {
				showToast({
					type:'error',
					message:e.data.message
				})
			}
		})

	}

	const onEditConditions = (data,id) =>{
		post(`vendor/procurement/poCondition/updatePoItemCondition/${id}`,data,(e,r)=>{
			if(r) {
					showToast({
						type:'success',
						message:"Line Item Updated successfully"
					});
					setState(prev => ({...prev,conditions:false,editable:undefined}))
					getMasterItem(id)
			} else {
			}
		})

	}
 
	const openConditions = (id) =>{
		if(showConditions && showConditions ===  id) {
			setState(prev => ({...prev,loading:true,showConditions:false}))
		} else {
			setState(prev => ({...prev,loading:true,showConditions:id}))
			getMasterItem(id)
		}
		
	}

	const handleUpdate=()=>{
		if(tab===1){
			if(!grnOwner) {
				showToast({
					type:'error',
					message:'Please select owner'
				})
				return;
			}
			if(grnOwner){
				post(`vendor/procurement/orders/poOwners/${id}/add`,{
					'employeeCode':grnOwner.code,
					'type':"GRN",
					'poItemId':updateOwnerModal.item.id
				},(e,r)=>{
					if(r){
						props.init();
						setGrnOwner(false);
						showToast({
							type: 'success',
							message: "GRN owner updated successfully"
						})
					}
					else if(e){
						handleError(e);
						return;
					}
				})
			}
		}
		else {
			if(!invoiceOwner) {
				showToast({
					type:'error',
					message:'Please select the owner'
				})
				return;
			}
			
			if(invoiceOwner){
				post(`vendor/procurement/orders/poOwners/${id}/add`,{
					'employeeCode':invoiceOwner.code,
					'type':"INVOICE",
					'poItemId':updateOwnerModal.item.id
				},(e,r)=>{
					if(r){
						props.init();
						setInvoiceOwner(false);
						showToast({
							type: 'success',
							message: "Invoice owner updated successfully"
						})
					}
					else if(e){
						handleError(e);
						return;
					}
				})
			}
		}
	}

	const handleConditionUpdate=()=>{
		if(tab===1){
			if(!grnOwner) {
				showToast({
					type:'error',
					message:'Please select owner'
				})
				return;
			}
			if(grnOwner){
				post(`vendor/procurement/orders/poOwners/${id}/add`,{
					'employeeCode':grnOwner.code,
					'type':"GRN",
					'poItemConditionId':updateConditionOwnerModal.item.poConditionItemId
				},(e,r)=>{
					if(r){
						getMasterItem(lineItemId);
						setGrnOwner(false);
						showToast({
							type: 'success',
							message: "GRN owner updated successfully"
						})
					}
					else if(e){
						handleError(e);
						return;
					}
				})
			}
		}
		else {
			if(!invoiceOwner) {
				showToast({
					type:'error',
					message:'Please select the owner'
				})
				return;
			}
			
			if(invoiceOwner){
				post(`vendor/procurement/orders/poOwners/${id}/add`,{
					'employeeCode':invoiceOwner.code,
					'type':"INVOICE",
					'poItemConditionId':updateConditionOwnerModal.item.poConditionItemId
				},(e,r)=>{
					if(r){
						getMasterItem(lineItemId);
						setInvoiceOwner(false);
						showToast({
							type: 'success',
							message: "Invoice owner updated successfully"
						})
					}
					else if(e){
						handleError(e);
						return;
					}
				})
			}
		}
	}

	const deleteElement=(type,code)=>{
		if(!type || !code){
			showToast({
				type: 'error',
				message: "Employee code or type not found"
			})
			return;
		}
		deleteApi(`/vendor/procurement/orders/poOwners/${id}/delete?employeeCode=${code}&type=${type}&poItemId=${updateOwnerModal.item.id}`,(e,r)=>{
			if(r){
				props.init();
				showToast({
					type: 'success',
					message: "Owner removed successfully"
				})
			}
			else{
				showToast({
					type:'error',
					message:"Error in removing the owner"
				})
				return;
			}
		})
	}

	const deleteConditionElement=(type,code)=>{
		if(!type || !code){
			showToast({
				type: 'error',
				message: "Employee code or type not found"
			})
			return;
		}
		deleteApi(`/vendor/procurement/orders/poOwners/${id}/delete?employeeCode=${code}&type=${type}&poItemConditionId=${updateConditionOwnerModal.item.poConditionItemId}`,(e,r)=>{
			if(r){
				getMasterItem(lineItemId);
				showToast({
					type: 'success',
					message: "Owner removed successfully"
				})
			}
			else{
				showToast({
					type:'error',
					message:"Error in removing the owner"
				})
				return;
			}
		})
	}
	
	const getMasterItem = (id) =>{
		get("/vendor/procurement/poCondition/getPoItemConditions", (e, r) => {
			if (r) {
				setState(prev => ({...prev,allConditions:r.poConditions , loading:false}))
			} else {
				setState(prev => ({...prev, loading:false}))
			
			}
		},{
			filter:`${id}@t0.procured_item_id`
		});
	}
	
	const  deleteItemCondition = (id,itemId) =>{
		setState(prev => ({...prev,loading:true}))
		deleteApi(`/vendor/procurement/poCondition/removePoItemCondition/${id}`, (e, r) => {
			if (r) {
				showToast({
					type:'success',
					message:"Line Item condition deleted successfully"
				});
			    getMasterItem(itemId)
			} else {
				setState(prev => ({...prev,loading:false}))
			}
		});
	}
	
	let itemCount  = 0;
	return (
		<div>
			{
				rq?<RfqModal id={rq} close={()=>{setRq(0)}}/>:undefined
			}
			<div>
				{conditionalPo ? 
		    	<ToggleSwitch style={{marginLeft:-10}} label="Vendor Condition " status={toggle} callback={()=>{ setState(prev => ({...prev,toggle:!prev.toggle}))}}  />
				: null}
				<div style={{fontSize: '12px', fontWeight: 'bold', marginBottom: '10px', marginTop:10}}>AS PER PURCHASE ORDER</div>
				<table style={{border: '2px solid var(--border-color)', borderCollapse: 'collapse', fontSize: '12px', width: '100%'}}>
				<thead>
				<tr style={{background: 'var(--lite-color)', textAlign: 'left'}}>
					<th style={{padding: '15px', minWidth: 's150px'}}>DETAILS</th>
					<th style={{padding: '15px'}}>DELIVERY ADDRESS</th>
					<th style={{padding: '15px'}}>QTY / UNIT</th>
					<th style={{padding: '15px'}}>Price / UNIT</th>

					<th style={{padding: '15px'}}>DELIVERED</th>
					<th style={{padding: '15px'}}>Invoice Qty</th>
					<th style={{padding: '15px'}}>Balance Qty</th>
					{props.currency==='INR'?<th style={{padding: '15px'}}>GST</th>:''}
					<th style={{padding: '15px'}}>AMOUNT</th>
					<th style={{width:'50px'}}>Actions</th>
					{status === 'DRAFT' && conditionalPo && 
					<th style={{width:'50px'}}>Conditions</th>
		          }
				</tr>
				</thead>
				<tbody>
				{
					procured.length === 0 ?
					<tr style={{background: '#fff'}}>
						<td style={{padding: '10px'}}>
							Nothing procured yet
						</td>
					</tr>
					:procured.map((item,index)=>{
						return (
						<>
						 	<tr style={{background: '#fff',borderTop:'10px solid var(--border-color)',paddingBottom:20}}>
								<td  style={{padding: '15px'}}>
										<p onClick={()=>{
											setRq(item.purchase)
										}} style={{
											fontSize: 16,
											color: 'var(--primary-color)',
											cursor:'pointer',
											alignItems: 'center'
											}} >
											{item.purchaseId}
										</p>
										<br/>
										 <p style={{fontWeight:600,fontSize:15}}>{item.name} </p><br/>
										 {
											item.attrs && Object.keys(item.attrs).length>0 &&
											item.attrs['lineItemReferenceNo']  ?
											<p>Line Item Reference No : {item.attrs['lineItemReferenceNo']}</p> :''

										 }
										 {
											item.attrs && Object.keys(item.attrs).length>0 &&
											item.attrs['SO Number']  ?
											<p>SO Number : {item.attrs['SO Number']}</p> :''

										 }
										Item Ref no: {item.id}<br/>
										DESC:{item.description} <br/>
										HSN : {item.hsn} <br/>
										SKU : {item.sku} <br/>
										productCode : {item.productCode?item.productCode:'-'} <br/>
										notes:{item.notes?item.notes:''} <br/>
										{item.budgetId && `budget id : ${item.budgetId}`}<br/>
										{item.budgetStatus && <>budget status : {item.budgetStatus}<br/></>}
										{item.budgetAmount && <>budget amount : {item.budgetAmount}<br/></>}
										{item.attrs && item.attrs.autoBudgeting && <>Auto Budgeting : {item.attrs.autoBudgeting}<br/></>}
										{/* Render all other attributes dynamically except excluded ones */}
										{item.attrs &&
										    Object.entries(item.attrs)
											.filter(([key]) => !excludeAttars.includes(key)).length>0 && (
										 <p style={{ fontWeight: 500, color: 'grey' }}>Custom Form</p>
										)}
										{item.attrs && Object.entries(item.attrs)
											.filter(([key]) => !excludeAttars.includes(key))
											.map(([key, value]) => (
												<div style={{ flexDirection: 'column' }} key={key}>
													<p style={{ fontWeight: 250, color: 'grey' }}> {key}: <span style={{ fontWeight: 500, color: 'black' }}>{value}</span></p>
													<br />
												</div>
											))
										}
										{
											conditionalPo ?
											<>
											  Freight PO : 
											  <span onClick={()=>{
												if(conditionalPos[item.id].freightPo) {
													window.open(`/app/vendor/procurement/order/${conditionalPos[item.id].freightPo}/details`,'_blank')
												}
											  }} style={{color:'var(--primary-color)',cursor:'pointer'}}>{conditionalPos[item.id]?conditionalPos[item.id].freightPo:'-'}</span> <br />
											  Handling PO : 
											  <span onClick={()=>{
												if(conditionalPos[item.id].handlingPo) {
													window.open(`/app/vendor/procurement/order/${conditionalPos[item.id].handlingPo}/details`,'_blank')
												}
											  }} style={{color:'var(--primary-color)',cursor:'pointer'}}>{conditionalPos[item.id]?conditionalPos[item.id].handlingPo:'-'}</span> <br/>
											  Rail PO : 
											  <span  onClick={()=>{
												if(conditionalPos[item.id].railPo) {
													window.open(`/app/vendor/procurement/order/${conditionalPos[item.id].railPo}/details`,'_blank')
												}
											  }} style={{color:'var(--primary-color)',cursor:'pointer'}}>{conditionalPos[item.id]?conditionalPos[item.id].railPo:"-"}</span> <br/>

											   Other PO : 
											  <span  onClick={()=>{
												if(conditionalPos[item.id].railPo) {
													window.open(`/app/vendor/procurement/order/${conditionalPos[item.id].otherPo}/details`,'_blank')
												}
											  }} style={{color:'var(--primary-color)',cursor:'pointer'}}>{conditionalPos[item.id]?conditionalPos[item.id].otherPo:"-"}</span> <br/>
											</>
											:null
										}
									{
										item.specs?<div>File: {item.specs ? <span onClick={()=>window.open(item.specs,'_blank')} style={{color: '#6c5dd3', cursor: 'pointer', fontWeight: 'bold'}}>View&nbsp;&nbsp;&nbsp;</span>:"-"}</div>:undefined
									}
								   
								</td>
								
								<td style={{padding: '15px'}}>{item.officeName} {invoices.length === 0 || (invoices.length> 0 && invoices.every(e => e.status === "DECLINED")) ?
								     <ReadOnly><div className="penciledit" onClick={()=>{
									setAddressType(item.id)
								}} style={{display:'inline-block',height:10,width:10}}/></ReadOnly>: null} 
								<br/>
								Registered Address :{item.officeAddress?item.officeAddress:'-'}
								 <br/>Delivery Date: {Moment(item.deliveryDate).format('DD-MM-YYYY')}</td>
								<td style={{padding: '15px'}}>{item.qtty} {item.unit}</td>
								<td style={{padding: '15px'}}>{props.currency} {item.price}</td>

								<td style={{padding: '15px'}}>{item.grnQty>item.approved?item.grnQty: item.approved}</td>
								<td style={{padding: '15px'}}>{item.invoicedQty}</td>
								<td style={{padding: '15px'}}>{item.qtty-(item.grnQty>item.approved?item.grnQty: item.approved)}</td>

								{props.currency==='INR'?<td style={{padding: '15px'}}>{item.taxSlab}%</td>:''}

								<td style={{padding: '15px'}}>{props.currency} {numberWithCommas(item.amount.toFixed(2))}</td>
								{status !== 'COMPLETED' ?
								<td>
								<ReadOnly>
								<div onClick={()=>{setEdit(item) }}
								       style={{width:50,cursor:'pointer',color:'var(--primary-color)'}}>
										Edit
									</div>
									</ReadOnly>		
								{(status === 'DRAFT' ||  status === 'PENDING') && 
								<ReadOnly>
							    	<div onClick={()=>{setRemove(item) }}
								       style={{width:50, cursor:'pointer',color:'var(--red-color)',marginTop:10}}>
										Remove
									</div>
								</ReadOnly>		
							  	}
								{ 
									ownerMappingAtPoItemLevel && 
									<ReadOnly>
										<div onClick={()=>{setUpdateOwnerModal({item,index})}}
										style={{width:50, cursor:'pointer',color:'orange',marginTop:10}}>
											Update Owner
										</div>
									</ReadOnly>		
							  	}
						     	</td>    
								: null}
							   {(status === 'DRAFT') && conditionalPo ?
								<td onClick={()=>{
									setState(prev => ({...prev,conditions:item,showConditions:false}))
								}} style={{width:50,cursor:'pointer',color:'var(--primary-color)'}}>
									+ Add
								</td>
									: null}
								</tr>
					 	  {conditionalPo && 
							<td colSpan={9}>
							  <div
								onClick={() => {
									setLineItemId(item.id)
									openConditions(item.id)
								}}
								style={{
									display: 'flex',
									alignItems: 'center',
									paddingLeft: 10,
									paddingRight: 10, 
									justifyContent: 'space-between',
									background: 'var(--lite-color)',
									fontFamily:'sans-serif',
									fontWeight:'500',
									fontSize: '16px',
									height: 40,
									marginLeft:10,
									marginRight:10,
									cursor: 'pointer',
									userSelect: 'none',
								}}>
								<span>Conditions</span>
								<span
									style={{
										transform: showConditions && showConditions === item.id ? 'rotate(90deg)' : 'none',
										transition: 'transform 0.3s ease-in-out'
									}}
								   >
									▶
								</span>
							</div>
                            {showConditions && showConditions === item.id  ? (
                                <table style={{ border: '1px solid var(--border-color)', borderCollapse: 'collapse', fontSize: '12px', marginLeft:10,marginRight:10, overflow:'hidden' }}>
                                    <tbody>
                                        <tr style={{ background: 'var(--lite-color)', textAlign: 'left' }}>
                                            <th style={{ padding: '15px', minWidth: '150px' }}>Condition name</th>
                                            <th style={{ padding: '15px' }}>Condition Price</th>
                                            <th style={{ padding: '15px', textAlign: 'right' }}>Tax Slabs</th>
                                            <th style={{ padding: '15px', textAlign: 'right' }}>Vendor for Condition</th>
                                            <th style={{ padding: '15px', textAlign: 'right' }}>PO for Condition</th>
                                            <th style={{ padding: '15px', textAlign: 'right' }}>Qty</th>
											<th style={{ padding: '15px', textAlign: 'right' }}>Grn Qty</th>
                                            <th style={{ padding: '15px', textAlign: 'right' }}>Note</th>
									     	{status === 'DRAFT' &&  <ReadOnly><th style={{ padding: '15px', textAlign: 'right' }}>Edit</th></ReadOnly> }
										 	{status === 'DRAFT' &&  <ReadOnly><th style={{ padding: '15px', textAlign: 'right' }}>Delete</th></ReadOnly>}
											{ownerMappingAtPoItemLevel &&  <ReadOnly><th style={{ padding: '15px', textAlign: 'right' }}>Action</th></ReadOnly>}
											
                                        </tr>
										{loading ?
								     	 <Spinner /> : 
									      allConditions.length  ? 
										   allConditions.map((e,index) =>{
											itemCount += (e.total/item.qtty)
											// itemCount += (e.price * e.qty)*(1+e.taxSlab)
											return(
										 	<tr style={{ background: '#fff' }}>
												<td style={{ padding: '15px', minWidth: '10px' }}>{e.title ||'-'}</td>
												<td style={{ padding: '15px' }}>{e.price || '-'}</td>
												<td style={{ padding: '15px', textAlign: 'right' }}>{e.taxSlab ||''}</td>
												<td style={{ padding: '15px', textAlign: 'right' }}>{e.vendorName || "-"}</td>
												<td style={{ padding: '15px', textAlign: 'right' }}>{e.conditionalPoNo || '-'}</td>
												<td style={{ padding: '15px', textAlign: 'right' }}>{e.qty || '-'}</td>
												<td style={{ padding: '15px', textAlign: 'right' }}>{e.grnQty || '-'}</td>
												<td style={{ padding: '15px', textAlign: 'right' }}>{e.notes || '-'}</td>
												{status === 'DRAFT' &&
												<ReadOnly><td 
												onClick={() => {
													setState(prev => ({...prev,conditions:item,editItem:e,showConditions:false}))
												}} 
												style={{padding: '13px', textAlign: 'right',cursor:'pointer',color:'var(--primary-color)',minWidth: '100px'}}>
												   Edit
												</td></ReadOnly>
												}
												 {status === 'DRAFT' && 
												 <ReadOnly>
												 <td 
												 onClick={() => deleteItemCondition(e.poConditionItemId,item.id)} 
											     style={{padding: '14px', textAlign: 'right',cursor:'pointer',color:'var(--red-color)'}}>
												   Delete
												</td>
												 </ReadOnly>
										        }
												{ownerMappingAtPoItemLevel && 
												 <ReadOnly>
												 <td 
												 onClick={() => setUpdateConditionOwnerModal({item:e,index})} 
											     style={{padding: '14px', textAlign: 'right',cursor:'pointer',color:'orange'}}>
												   Update Owner
												</td>
												 </ReadOnly>
										        }
											  </tr>
											)
									     	}) 
									     :  null}
										 {allConditions.length  ? (
											<tr style={{backgroundColor:'rgba(222,223,220,1)',padding:'20px',height:'40px'}}>
											 <td style={{justifyContent:'flex-end',}}>{''}</td>
										     <td style={{justifyContent:'flex-end',}}>{''}</td>
										     <td style={{justifyContent:'flex-end',}}>{''}</td>
										     <td style={{justifyContent:'flex-end',}}>{''}</td>
										     <td style={{justifyContent:'flex-end',}}>{''}</td>
											 {status === 'DRAFT' && 
											  <>
											   <td style={{justifyContent:'flex-end',}}>{''}</td>
										     <td style={{justifyContent:'flex-end',}}>{''}</td>
											 </>
											 }
										     
										     <td style={{justifyContent:'flex-end', fontWeight:500,fontSize:'14px'}}>Cost Per Unit</td>
										     <td style={{justifyContent:'flex-end',fontWeight:500,fontSize:'14px'}}>{(itemCount + (item.amount/item.qtty)).toFixed(2) !== '0' && (itemCount + (item.amount/item.qtty)).toFixed(2)}</td>
											</tr>
										 ): null}
                                    </tbody>
                                </table>
                            ) : null }
                        </td>
				     	}
						 </>
						)
					})
				}
			   </tbody>
				</table>
				<table style={{border: '1px solid var(--border-color)', borderCollapse: 'collapse', marginTop:10,fontSize: '12px', width: '100%'}}>
					<tr style={{background: 'var(--lite-color)', textAlign: 'left'}}>
						<th style={{padding: '15px', minWidth: '150px'}}>Type</th>
						<th style={{padding: '15px', minWidth: '150px'}}>Total Items</th>
						<th style={{padding: '15px'}}>Total QTY</th>
						<th style={{padding: '15px',textAlign:'right'}}>Total Amount</th>
					</tr>
					<tr style={{background: '#fff',}}>
						<th style={{padding: '15px', minWidth: '150px',textAlign:'left',background: '#fff',}}>{'Main Item'}</th>
						<th style={{padding: '15px', minWidth: '150px',textAlign:'left',background: '#fff',}}>{count}</th>
						<th style={{padding: '15px',textAlign:'left',background: '#fff',}}>{parseFloat(qty).toFixed(3)} Units</th>
						<th style={{padding: '15px',textAlign:'right',background: '#fff',}}>{props.currency} {numberWithCommas(total)}</th>
					</tr>
					<tr style={{background: '#fff',}}>
						<th style={{padding: '15px', minWidth: '150px',textAlign:'left',background: '#fff',}}>{''}</th>
						<th style={{padding: '15px', minWidth: '150px',textAlign:'left',background: '#fff',}}>{''}</th>
						<th style={{padding: '15px',textAlign:'left',background: '#fff',}}>{'Base Amount'} </th>
						<th style={{padding: '15px',textAlign:'right',background: '#fff',}}> {props.currency} {base?numberWithCommas(base):base}</th>
					</tr>
					{
						props.currency==='INR' ?
						<>
						<tr style={{background: '#fff',}}>
							<th style={{padding: '15px', minWidth: '150px',textAlign:'left',background: '#fff',}}>{''}</th>
							<th style={{padding: '15px', minWidth: '150px',textAlign:'left',background: '#fff',}}>{''}</th>
							<th style={{padding: '15px',textAlign:'left',background: '#fff',}}>{'CGST'} </th>
							<th style={{padding: '15px',textAlign:'right',background: '#fff',}}> {props.currency} {numberWithCommas(cgst)}</th>
						</tr>
						<tr style={{background: '#fff',}}>
							<th style={{padding: '15px', minWidth: '150px',textAlign:'left',background: '#fff',}}>{''}</th>
							<th style={{padding: '15px', minWidth: '150px',textAlign:'left',background: '#fff',}}>{''}</th>
							<th style={{padding: '15px',textAlign:'left',background: '#fff',}}>{'SGST'} </th>
							<th style={{padding: '15px',textAlign:'right',background: '#fff',}}> {props.currency} {numberWithCommas(sgst)}</th>
						</tr>
						<tr style={{background: '#fff',}}>
							<th style={{padding: '15px', minWidth: '150px',textAlign:'left',background: '#fff',}}>{''}</th>
							<th style={{padding: '15px', minWidth: '150px',textAlign:'left',background: '#fff',}}>{''}</th>
							<th style={{padding: '15px',textAlign:'left',background: '#fff',}}>{'IGST'} </th>
							<th style={{padding: '15px',textAlign:'right',background: '#fff',}}> {props.currency} {numberWithCommas(igst)}</th>
						</tr>
						</>
						: <>
							<tr style={{background: '#fff',}}>
								<th style={{padding: '15px', minWidth: '150px',textAlign:'left',background: '#fff',}}>{''}</th>
								<th style={{padding: '15px', minWidth: '150px',textAlign:'left',background: '#fff',}}>{''}</th>
								<th style={{padding: '15px',textAlign:'left',background: '#fff',}}>{'Tax'} </th>
								<th style={{padding: '15px',textAlign:'right',background: '#fff',}}> {props.currency} {numberWithCommas(tax?tax:0)}</th>
							</tr>
						</>
					}
					{conditionalPo ? 
					<tr style={{background: '#fff'}}>
						<th style={{padding: '15px', minWidth: '150px',textAlign:'left',background: '#fff',}}>{'Item Conditions'}</th>
						<th style={{padding: '15px', minWidth: '150px',textAlign:'left',background: '#fff',}}>{cCount}</th>
						<th style={{padding: '15px',textAlign:'left',background: '#fff',}}>{parseFloat(cQty).toFixed(3)} Units</th>
						<th style={{padding: '15px',textAlign:'right',background: '#fff',}}>{props.currency} {numberWithCommas(cTotal)}</th>
					</tr>
					: null
				      }
					{conditionalPo ? 
					<tr style={{background: '#fff'}}>
						<th style={{padding: '15px', minWidth: '150px',textAlign:'left',background: '#fff',}}>{''}</th>
						<th style={{padding: '15px', minWidth: '150px',textAlign:'left',background: '#fff',}}>{''}</th>
						<th style={{padding: '15px',textAlign:'left',background: '#fff',}}>{''}</th>
						{/* <th style={{padding: '15px',textAlign:'left',background: '#fff',}}>Total Unit cost {`${((cTotal+total) / qty).toFixed(3)}` }</th> */}
						<th style={{padding: '15px',textAlign:'right',background: '#fff',}}>{props.currency} {numberWithCommas(cTotal+total)}</th>
					</tr>
					: null
					}
				</table>
			</div>

			<div>
				<div style={{fontSize: '12px', fontWeight: 'bold', marginBottom: '10px', marginTop: '30px'}}>AMENDMENTS</div>
				<table style={{border: '1px solid var(--border-color)', borderCollapse: 'collapse', fontSize: '12px', width: '100%'}}>
				<tr style={{background: 'var(--lite-color)', textAlign: 'left'}}>
					<th style={{padding: '15px', minWidth: '150px'}}>DETAILS</th>
					<th style={{padding: '15px'}}>DELIVERY ADDRESS</th>
					{props.currency==='INR'?<th style={{padding: '15px'}}>GST</th>:''}
					<th style={{padding: '15px'}}>QTY / UNIT</th>
					<th style={{padding: '15px'}}>Ament Quantity</th>
					<th style={{padding: '15px'}}>AMOUNT</th>
					<th></th>
				</tr>
				{
					procured.length === 0 ?
					<tr style={{background: '#fff'}}>
						<td style={{padding: '10px'}}>
							No Amendments
						</td>
					</tr>
					:procured.map(item=>{
						if(parseInt(item.qtty)-parseInt(item.approved)<0){
							Amendments=false;
							return (
								<tr style={{background: '#fff'}}>
									<td style={{padding: '15px'}}>
											<Link to={`/app/vendor/procurement/indent/${item.purchase}/details`} style={{
												fontSize: 12,
												color: 'var(--primary-color)',
												alignItems: 'center'
												}} target="_blank">
												{item.purchaseId}
											</Link>
											<br/>
											{item.name}<br/>
											HSN : {item.hsn} <br />
											productCode : {item.productCode} <br />

										{
											item.specs?
												<div>File: {item.specs ? <span onClick={()=>window.open(item.specs,'_blank')} style={{color: '#6c5dd3', cursor: 'pointer', fontWeight: 'bold'}}>View&nbsp;&nbsp;&nbsp;</span>:"-"}</div>:undefined
										}
									</td>
									<td style={{padding: '15px'}}>{item.officeName}

									<br/>Date: {Moment(item.deliveryDate).format('DD-MM-YYYY')}</td>
									{props.currency==='INR'?<td style={{padding: '15px'}}>{item.taxSlab}%</td>:''}
									<td style={{padding: '15px'}}>{item.qtty} {item.unit}</td>
									<td style={{padding: '15px'}}>{item.approved}</td>
									<td style={{padding: '15px'}}>{props.currency} {numberWithCommas(item.amount)}</td>
								</tr>
							)
						}else{
							return null
						}
					})
				}
				{
					Amendments?
					<tr style={{background: '#fff'}}>
						<td style={{padding: '10px'}}>
							No Amendments
						</td>
					</tr>
					:<></>
				}
				</table>
			</div>
			{
				props.data.status==='COMPLETED'?
				<div>
				<div style={{fontSize: '12px', fontWeight: 'bold', marginBottom: '10px', marginTop: '30px'}}>SHORT CLOSURE</div>
				<table style={{border: '1px solid var(--border-color)', borderCollapse: 'collapse', fontSize: '12px', width: '100%'}}>
				<tr style={{background: 'var(--lite-color)', textAlign: 'left'}}>
					<th style={{padding: '15px', minWidth: '150px'}}>DETAILS</th>
					<th style={{padding: '15px'}}>DELIVERY ADDRESS</th>
					{props.currency==='INR'?<th style={{padding: '15px'}}>GST</th>:''}
					<th style={{padding: '15px'}}>QTY / UNIT</th>
					<th style={{padding: '15px'}}>DELIVERED</th>
					<th style={{padding: '15px'}}>AMOUNT</th>
					<th></th>
				</tr>
				{
					procured.length === 0 ?
					<tr style={{background: '#fff'}}>
						<td style={{padding: '10px'}}>
							Nothing procured yet
						</td>
					</tr>
					:procured.map(item=>{
						if(parseInt(item.qtty)-parseInt(item.approved)>0){
							return (
								<tr style={{background: '#fff'}}>
									<td style={{padding: '15px'}}>
											<Link to={`/app/vendor/procurement/indent/${item.purchase}/details`} style={{
												fontSize: 12,
												color: 'var(--primary-color)',
												alignItems: 'center'
												}} target="_blank">
												{item.purchaseId}
											</Link>
											<br/>
											{item.name}<br/>
											{item.vendor}<br/>
											HSN : {item.hsn}
											File: {item.Specs ? <span onClick={()=>window.open(item.Specs,'_blank')} style={{color: '#6c5dd3', cursor: 'pointer', fontWeight: 'bold'}}>View&nbsp;&nbsp;&nbsp;</span>:"-"}

									</td>
									<td style={{padding: '15px'}}>{item.officeName}<br/>Date: {Moment(item.deliveryDate).format('DD-MM-YYYY')}</td>
									{props.currency==='INR'?<td style={{padding: '15px'}}>{item.taxSlab}%</td>:''}
									<td style={{padding: '15px'}}>{item.qtty} {item.unit}</td>
									<td style={{padding: '15px'}}>{item.approved}</td>
									<td style={{padding: '15px'}}>{props.currency} {item.amount}</td>
								</tr>
							)
						}else{
							return null
						}
					})
				}
				</table>
			</div>
			:<></>
			}

				{
				addressType ?
					<Modal
						show={true}
						title="Change Address"
						des="Change delivery address"
						close={()=>{
							setAddressType(false);
						}}
						onSave={()=>{
							let data = {};
							let remarks = REMARKS.current.value;

							if(office) {
								data["shipTo"] = `${office.value}`;
							}
							if(date.current.getValue()) {
								data.date = `${date.current.getValue()}`;
							}

							if(!remarks) {
								showToast({
									type:'error',
									message:"Please enter the remarks"
								})
								return;
							}

							data.remarks  = remarks;

							if(Object.keys(data).length) {
								let count = 0;
								Object.keys(data).forEach(val=>{
									let mData = {key:val,value:data[val]}
									if(val==='remarks') {
										mData = {...mData, remarks:data[val]}
									}
									if(status==='GRN' || status==='PENDING') {
										mData.force = true
									}
									mData.itemId = addressType;
									post(`vendor/po/requests/${id}/updateDetails`,mData,(e,r)=>{
										if(r) {
											count++;
											if(count===Object.keys(data).length) {
												showToast({
													type:'success',
													message:"Line Item Update successfully"
												});
												setAddressType(false);
												setTimeout(()=>{
													props.init();
												},2000)
											}
										} else {
											showToast({
												type:'error',
												message:e.data.message
											})
										}
									})
								})
							} else {
								showToast({
									type:'success',
									message:"Line Item Update successfully"
								});
								setAddressType(false);
	
							}
							
						}}
					>
						<SearchOffice2 p2p={true} title="Search office" office={office} onSelect={val=>{
							setOffice(val)
						}} />

						<FloatDate title="Delivery Date" handleRef={ref=>date.current=ref} />
						<FunctionalInput title="Remarks" type="text" ref={REMARKS} />
					</Modal>
					: null
			}

		{
			edit ?
				<Modal style={{
					save: {
						background: "var(--primary-color)",
						width: "100%",
						border: "2px solid var(--primary-color)",
						padding: '12px 15px',
						textAlign: 'center'
					}
				}} title={"Update Line Item"} onSave={() => {

						let qtty = QTY.current.value;
						if(!qtty) {
							showToast({
								type:"error",
								message:"Please enter the quantity"
							})
							return;
						}
						
						let price = PRICE.current.value;
						if(!price) {
							showToast({
								type:"error",
								message:"Please enter the price"
							})
							return;
						}
						if(!editProductTax) {
							showToast({
								type:"error",
								message:"Please enter the tax"
							})
							return;
						}

						let remarks = REMARKS.current.value;

						if(!remarks && !optionalFields) {
							showToast({
								type:"error",
								message:"Please enter the remarks"
							})
							return;
						}
						if(remarks && !optionalFields &&  remarks.trim().length < 10){
							showToast({
								type:"error",
								message:"Minimum 10 characters required"
							})
							return;
						}
					
						if(Object.keys(update).length) {
							let count = 0;
							Object.keys(update).forEach(val=>{
								let data = {key:val,value:update[val],itemId:edit.id}
								if(val==='remarks' && !optionalFields) {
									data = {...data,remarks:update[val]}
								}
								if(status==='GRN' || status==='PENDING' || status==='INVOICE' || status === 'DRAFT') {
									data.force = true
								}
								post(`vendor/po/requests/${id}/updateDetails`,data,(e,r)=>{
									if(r) {
										count++;
										if(count===Object.keys(update).length) {
											showToast({
												type:'success',
												message:"Line Item Update successfully"
											});
											if(conditionalPo && status === 'DRAFT') {
												let freightPo = FREIGHTPO.current.getValue()
												let railPo = RAILPO.current.getValue()
												let handlingPo = HANDLINGPO.current.getValue()
												let otherPo = OTHERPO.current.getValue()


												post(`vendor/po/requests/${id}/updateConditionalPos`,{

													conditionalPos:{
														items:{
															[edit.id]:{
																freightPo,
																railPo,
																handlingPo,
																otherPo
															}
														}
													}
												},(e,r)=>{
													if(r) {
														setUpdate({});
														setEdit(false);
														props.init();
													}
												})
											} else {
												setUpdate({});
												setEdit(false);
									
												props.init();
											}
											
										}
									} else {
										showToast({
											type:'error',
											message:e.data.message
										})
									}
								})
							})
						} else {
							showToast({
								type:'success',
								message:"Line Item Update successfully"
							});
							setEdit(false);

						}
						

				}} buttonName={'Update Data'}
					   des="Please update order item"
					   show={true}
					   close={() => {
						   setEdit(0)
					   }}>
					<FunctionalInput title="Quantity" type="number" ref={QTY} onChange={e=>
						{
							let sItem={...update}
							sItem["qty"] = parseFloat(e.target.value).toFixed(3);
							let value = e.target.value
							let invoiceQtty = edit["invoicedQty"];
							let grnQtty = edit["grnQty"];
							if((invoiceQtty || grnQtty) && (value<grnQtty || value<invoiceQtty) ) {
								showToast({
                                    type:'error',
                                    message:"Quantity cannot be less than the grn or invoice quantity"
                                })

								
								QTY.current.value = invoiceQtty||grnQtty;
								sItem["qty"] = parseFloat(QTY.current.value).toFixed(3);
								setUpdate(sItem);
                                return;
							}
							setUpdate(sItem)


						}
					} 
						/>
					<FunctionalInput title="Price" type="number" ref={PRICE}  onChange={e=>
						{
							let sItem={...update}
							sItem["price"] = e.target.value
							setUpdate(sItem)
						}
					} />
					<SelectGST defaultValue={editProductTax} onSelect={(val)=>{
							setEditProductTax(val);
							let sItem={...update};
							let value = (val.value).toString();
							sItem["tax"] = value;
							setUpdate(sItem)
						}} placeholder="Select Gst" />
					<FunctionalInput title="Hsn" type="text" ref={HSN}  onChange={e=>
						{
							let sItem={...update}
							sItem["hsn"] = e.target.value;
							setUpdate(sItem)
						}
					} />
					<FunctionalInput title="Notes" type="text" ref={NOTES}  onChange={e=>
						{
							let sItem={...update}
							sItem["notes"] = e.target.value;
							setUpdate(sItem)
						}
					} />

					

					{
						conditionalPo && status==='DRAFT' ?
						<>
							<SearchPO ref={FREIGHTPO} label="FREIGHT PO" placeholder="FREIGHT PO" />
							<SearchPO ref={RAILPO} label="RAIL PO" placeholder="RAIL PO"/>
							<SearchPO ref={HANDLINGPO} label="HANDLING PO" placeholder="HANDLING PO" />
							<SearchPO ref={OTHERPO} label="OTHER PO" placeholder="OTHER PO"  />

						</>
						:null
					}

					<FunctionalInput title="Remarks" type="text" ref={REMARKS}  onChange={e=>
						{
							let sItem={...update}
							sItem["remarks"] = e.target.value;
							setUpdate(sItem)
						}
					} />
					

				</Modal> : undefined
		}
        {conditions ? 
			<AddConditions 
				label={`${editItem ? 'Edit' : 'Add'} Conditions to Line Item`}
				des={`Please ${editItem ? 'edit' : 'add'} custom conditions on line item`}
				show={conditions}
				editable={editItem}
				id={conditions.id}
				update={onEditConditions}
				loading={false}
				onSave={addCondition}
				onClose={() => {
					setState(prev => ({...prev,conditions:false,editItem:false}))
				}}
		/>
		: null}
			{
		   remove ? 
			<Modal
	     		show={true}
				des="Please select value from drop down"
				title={"Remove item"}
				style={{
					save: {
						background: "var(--primary-color)",
						border: "2px solid var(--primary-color)",
						padding: '8px 15px',
						width: '100%',
						textAlign: 'center'
					}
				}}
				close={() => {
					setRemove(false)
				}}
				onSave={() => {
						let remarks = REMOVE_REMARKS.current.value || ''
						post(`vendor/po/requests/${id}/removePoItem`,
						{
							remarks,
							itemId:remove.id
						},
						(e,r)=>{
							if(r) {
								showToast({
									type:'success',
									message:"Line Item removed successfully"
								});
								props.init();
								setRemove(false)
							} else {
								showToast({
									type:'error',
									message:e.data.message
								})
							}
						})
				}}
				buttonName={"Remove"}
		       >
			 <div className="light-group" style={{ marginBottom: 20 }}>
			 <FunctionalInput type="text" title="Enter Remarks" ref={REMOVE_REMARKS} />
			 </div>
		   </Modal>
			:null}
			{
				updateOwnerModal?<Modal
					close={()=>{
						setUpdateOwnerModal(false)
						setGrnOwner(false);
						setInvoiceOwner(false);
					}}
					hideButton={true}
					show={true}
					title="Update owners at line item level"
					des={"Add/Update owners at line item level for GRN and Invoice"}
					style={
						{
							save: {
								width: '100%',
								fontSize: 17,
								height: 44,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}
						}
					}
					>
					<TabSwtich tabs={tabs} />
					{
						tab===1?<Owner Owner={grnOwner} setOwner={setGrnOwner} onClick={handleUpdate} item={updateOwnerModal.item} deleteElement={deleteElement} type="GRN"/>
							:
						<Owner Owner={invoiceOwner} setOwner={setInvoiceOwner} onClick={handleUpdate} item={updateOwnerModal.item} deleteElement={deleteElement} type="INVOICE"/>
					}

				</Modal>:null
			}
			{
				updateConditionOwnerModal?<Modal
					close={()=>{
						setUpdateConditionOwnerModal(false)
						setGrnOwner(false);
						setInvoiceOwner(false);
					}}
					hideButton={true}
					show={true}
					title="Update owners at line item condition level"
					des={"Add/Update owners at line item condition level for GRN and Invoice"}
					style={
						{
							save: {
								width: '100%',
								fontSize: 17,
								height: 44,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}
						}
					}
					>
					<TabSwtich tabs={tabs} />
					{
						tab===1?<ConditionOwner Owner={grnOwner} setOwner={setGrnOwner} onClick={handleConditionUpdate} item={updateConditionOwnerModal.item} deleteElement={deleteConditionElement} type="GRN"/>
							:
						<ConditionOwner Owner={invoiceOwner} setOwner={setInvoiceOwner} onClick={handleConditionUpdate} item={updateConditionOwnerModal.item} deleteElement={deleteConditionElement} type="INVOICE"/>
					}

				</Modal>:null
			}

		</div>
	)

}

export default OrderItems
