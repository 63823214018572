import React, { useState, useEffect, useRef} from 'react'
import TabSwtich from "../../../../Common/fTabSwitch";
import axios, {get, post} from "../../../../../Model/Network/Config/Axios";
import { FloatInputButton, FunctionalInput} from "../../../../Common/fInputs";
import {FileUpload} from "../../../../Common/fInputs/FunctionalFile";
import PhotoTile from "../../../../Common/fPhoto";
import Track from "../../../../Common/fTrack";
import Moment from "moment";
import {handleError, showToast} from "../../../../Common/fToast/ToastCallback";
import {SidePane} from "../../../../Common/fSidePane/RightPane";
import Invoice from "./fInvoice";
import Tds from './Tds';
import {Spinner} from '../../../../Common/fLoader'
import moment from 'moment';
import PODetails from '../../PoDetails'
import { Modal } from '../../../../Common/fModal/Modal';
import { numberWithCommas } from '../../../../../Utils/NumberToComma';
import { NormalTable } from '../../../../Tables/NormalTable/NormalTable';
import { KeyValue } from '../../../../Common/Components/KeyValue';
import { useReadAccessConfig } from '../../../../Hooks/ReadAccessConfig';
import { PageLoader } from '../../../../Common/Components/PageLoader';
import { ViewAttachment } from '../../../../Common/ViewAttachments';
import { SupportTicket } from '../../../../Common/SupportTicket';
import { ViewLedger } from '../../../fSettlements/fSettlements/fVendorLedger/VendorLedger/Helper/ViewLedger';
import { Allocation } from '../../../../Common/Allocation';
import { Integration } from '../../../../Common/Integration';
import { DropdownModal } from '../../../../Common/fModal/DropdownModal';
import { getBaseUrl } from '../../../../../Model/Network/Config/DecentralizeWrapper';
import { Form } from '../../../../Common/Class/Form';
import { useSelector } from 'react-redux';
import { timelineLogStatus } from '../../../fVendor/fProcurement/fPoAmend/GRN/GrnView/Timeline';
import { StateButton } from '../../../../Common/fStateButton';
import _ from 'lodash';
import SelectGST from '../../../../Common/fUtils/SelectGST';




export  default function India(props) {
	let {details,onReload,id,debitNote,debitNoteDetails} = props;


	const [getTab, setTab] = useState(1);
	const [items,setItems] = useState([]);
	const [Settlements, setSettlements] = useState(undefined);
	const [partial,setPartial] = useState(false);
	const [recall,setRecall] = useState(false);
	const [advance,setAdvance] = useState(false);
	const [loading,setLoading]  = useState(false);
	const [attachmentMode,setAttachmentMode] = useState(false);
	const [recallPartial,setRecallPartial] = useState(false);
	const [decline,setDecline] = useState(false);
	const [btnLoading,setbtnLoadin] = useState(false);
	let po = props.details.po && props.details.po.poId;
	let REMARKS=useRef(),REMARKS1=useRef(),AMOUNT=useRef(),REMARK=useRef(),PENALTY=useRef(), BILLNUMBER = useRef(), DATE = useRef();
	let [ledger,setLedger] = useState(undefined);
	let [files,setFiles] = useState([]);
	let readOnly = useReadAccessConfig();
	let FORM = useRef(),LINEITEMFORM = useRef({});
	const [lineItemCustomForm,setLineItemCustomForm] = useState([]);
	const [supply_return_case,setsupply_return_case] = useState("") //specific for jupiter
	const [cnBase,setCnBase] = useState("") //specific for jupiter
	const [onItemChange,setOnItemChange] = useState(false) //specific for jupiter
	const [cnGst,setCnGst] = useState({
		value: 0,
        label: 0,
	}) 
	const [enableGst,setEnableGst] = useState(false)
	const CONFIG = useSelector(state=>state.auth.config)
    const {creditDebitNoteRateEdit} = CONFIG.plugins;
    const CN_BASE = useRef()
	const tenant = CONFIG.tenant;


	useEffect(()=>{
		setItems(details.invoice.items.map(item=>{
			return {
				...item,
				original_qtty:item.qtty,
				credit_note_qtty: 0,
				credit_note_price: item.price,
				original_base:item.base,
                credit_note_amt:0,
				gross_amt: item.base - (item.base * (details.taxInformation.discount/100)),
				original_tax: item.tax
			}
		}));
	},[])


	useEffect(() => {
		fetchSettlements(id)
	}, []);

	useEffect(() => {
		if (onItemChange) {
			const updatedItems = items.map(item => {
				const proportion = item.base
					? item.base / items.reduce((sum, i) => sum + i.base, 0)
					: 0;
				const calculatedValue = cnBase * proportion;
				// Calculate grossBase and tax
				const grossBase = calculatedValue - (calculatedValue * (item.discountSlab || 0) / 100);
				const tax = grossBase * ((cnGst.value || 0) / 100);
				return {
					...item,
					grossBase, 
					tax:tax, 
					amount: calculatedValue + tax, 
					credit_note_qtty: 1, 
					credit_note_amt: calculatedValue.toFixed(2) ||'',
					taxSlab:cnGst.value,
				};
			});
			setItems(updatedItems);
		}
	}, [cnBase,onItemChange]);

	useEffect(() => {
		if (enableGst) {
			const totalBase = items.reduce((sum, item) => {
				return sum + (item.credit_note_amt || 0);
			}, 0);
	
			const updatedItems = items.map(item => {
				const creditNoteAmt = item.credit_note_amt || 0;
				const proportion = totalBase ? (creditNoteAmt / totalBase) : 0;
	
				const discountSlab = item.discountSlab || 0;
				const grossBase = creditNoteAmt - (creditNoteAmt * (discountSlab / 100));
	
				const taxSlab = +cnGst.value || 0;
				const tax = grossBase * (taxSlab / 100);
				const amount = grossBase + tax;
				return {
					...item,
					proportion, 
					distributedBase: creditNoteAmt, 
					taxSlab,
					tax, 
					amount,
					credit_note_amt: creditNoteAmt
				};
			});
	
			setItems(updatedItems);
		}
	}, [cnGst, enableGst]);

	useEffect(()=>{
		FORM.current.loadForm(`credit_note::category::${details.invoice.categoryId}`)
	},[])

	useEffect(()=>{
		get(`/forms/credit_note::item::${details.invoice.categoryId}`, (e, r) => {
			if (r) {
				setLineItemCustomForm(r.forms);
			}
		})
		items.forEach(el => {
			LINEITEMFORM.current[el.id].loadForm(`credit_note::item::${details.invoice.categoryId}`);
		})
	},[items])

	const fetchSettlements = (ID)=>{
		get(`settlements/settlements/invoice/${ID}/details`, (e,r)=>{
			if(r){
				setSettlements(r);
				setLoading(false);
			}else if(e){
				if(e.data.message==='Settlement not found'){
					setSettlements({settlements:[],settled:false})
				}else{
					handleError(e)
				}
				setLoading(false);
			}
		})
	}

	

	const uploadFile = (data) => {
		let items = [...files];
		items.push(data.file);
		setFiles(items);
	}

	const renderTags = (item, index) => {
		return (
			<div key={`Tag-${index}`} style={{display: 'flex', alignItems: 'center'}}>
				<FloatInputButton disabled={true} style={{width: '100%'}}  title={item[0]} placeholder={item[1]}/>
			</div>
		)
	}

	const mapLineItemTag = (item,index) =>{
		return(
			<div key={index} style={{border:'1px solid var(--border-color)',padding:12,borderRadius:12,marginTop:20}}>
				<FloatInputButton disabled={true} style={{width: '100%'}}  title={"Item Name Custom Form"} placeholder={item.name}/>
				{item.data.map((i,j)=>{
					return(
						<div key={`Tag-${j}`} style={{display: 'flex', alignItems: 'center'}}>
							<FloatInputButton disabled={true} style={{width: '100%'}}  title={i.key} placeholder={i.value}/>
						</div>
					)
				})}
			</div>
		)
	}

	const mapView = (item,index) => {
		return(
			<div key={index} style={{border:'1px solid var(--border-color)',padding:12,borderRadius:12,marginTop:20}}>
				<p style={{fontSize:12}}>{item.title}</p>
				<p style={{fontSize:10,marginTop:2}}>{item.description}</p>
				<p style={{fontSize:10,marginTop:2}}>Created At : {Moment(item.date).format('DD-MM-YYYY')}</p>
				{item.values.map((i,j)=>{
					return(
						<div key={`Tag-${j}`} style={{display: 'flex', alignItems: 'center'}}>
							<FloatInputButton disabled={true} style={{width: '100%'}}  title={i.key} placeholder={i.value}/>
						</div>
					)
				})}
			</div>
		)
	}

	let tabs = [
		{
			name: 'Format', active: getTab === 1 , onClick: () => {
				setTab(1)
			}
		},
		
		
		{
			name: 'Insight', active: getTab === 2 , onClick: () => {
				setTab(2)
			}
		},
		{
			name: 'Timeline', active: getTab === 3 , onClick: () => {
				setTab(3)
			}
		},
		{
			name: 'Settlements', active: getTab === 4 , onClick: () => {
				setTab(4)
			}
		},
		{
			name: 'Ticket', active: getTab === 6 , onClick: () => {
				setTab(6)
			}
		},
		
	]

	let {costAllocationDone,integrationData} = typeof details.content==='string' ? JSON.parse(details.content): details.content   //JSON.parse(details.content);

	if(integrationData&& Object.keys(integrationData).length) {
		tabs.push({
			name: 'Integration', active: getTab === 7 , onClick: () => {
				setTab(7)
			}
		})
	}

	if(po) {
		tabs.push({
			name: 'Po', active: getTab === 5 ,
			onClick: () => {
				setTab(5)
			}
		})
	}
	
	if(loading) {
		return <PageLoader />
	}

	return (
		<SidePane button={false} onClose={() => props.onClose(false)}
				  column={
					  <Invoice data={{
						  vendor: details.vendor.name,
						  vendorGstin:details.vendor.gstin,
						  email: details.vendor.email,
						  rcVendor:details.vendor.rcVendor,
						  vendor_id: details.vendor.gstin,
						  date: details.invoice.date,
						  due: details.dueOn?details.dueOn:undefined,
						  Invoice_id: details.invoice.invoiceId,
						  bill_add: details.address.address,
						  ship_add: details.deliverTo.address,
						  bill_to:details.address,
						  ship_to:details.deliverTo,
						  debitNote,
						  debitNoteDetails,
						  rcInvoice:details.invoice.rcInvoice,
						  table: [
							  ...items.map((item => {
								  return ({
									  referenceId:item.referenceId,
									  desc: item.name?item.name:item.service,
									  service:item.service,
									  price: item.price,
									  original_qtty:item.original_qtty,
									  credit_note_qtty: supply_return_case && supply_return_case === 'no' ?  1 : item.credit_note_qtty,
									  credit_note_price: item.credit_note_price,
									  base_amt: item.base,
									  gross_amt: item.base - (item.base * (details.taxInformation.discount/100)),
									  tax_amt: item.tax,
									  amount: item.amount,
									  hsn: item.hsn,
									  cessAmount:item.cessAmount?item.cessAmount:0,
									  debitQty:item.debitQty?item.debitQty:0,
									  forms : item.data,
									  id: item.id,
									  taxSlab:item.taxSlab? item.taxSlab:0,
									  ...(onItemChange || item.credit_note_amt) ? {credit_note_amt:item.credit_note_amt}:{}
								  })
							  }))
						  ],
						  cgst:details.taxInformation.cgst,
						  gst: details.taxInformation.gst,
						  sgst: details.taxInformation.sgst,
						  igst: details.taxInformation.igst,
						  tds: details.taxInformation.tds,
						  total_tax: details.taxInformation.gst,
						  total_base: details.taxInformation.baseAmount,
						  roundOff:details.taxInformation.roundOff,
						  total_amt: details.taxInformation.total,
						  payable_amt: details.taxInformation.paidAmount,
						  discount:details.taxInformation.discount || 0,
						  discountAmount:details.taxInformation.discountAmount ||0,
						  cessAmount:details.taxInformation.cessAmount,
						  status:details.status,
						  settlement:Settlements?Settlements.settled:true,
						  allSettlement:Settlements?Settlements.settlements:[],
						  mSettlement:Settlements?Settlements:false,
						  supply_return_case:supply_return_case,
						  supplyReturn:supply_return_case && supply_return_case === 'no'
					  }} 
					  onChangeQtty={(value,index)=>{
						  let invoiceitems = [...items];
						  let item = invoiceitems[index];
							let discountSlab = 0;
							let qtty = +value
                        	let base = item.credit_note_price * qtty;
                        	let grossBase = base - (base * (discountSlab/100));
							let tax = 0;
							if(!item.taxSlab && item.original_tax) {
								tax = (grossBase* ((item.original_tax/item.original_base)));
							} else {
								tax =(grossBase * (item.taxSlab / 100));
							}
							// item.base = base;
							item.tax = tax;
							item.amount = base+tax;
							item.credit_note_qtty = qtty;
							item.credit_note_amt = base;
							invoiceitems[index] = item;
							setItems(invoiceitems);
					  }}

					  onChangeAmt={(value,index)=>{
						let invoiceitems = [...items];
						let item = invoiceitems[index];
						let discountSlab = 0;
						let qtty = (value/item.credit_note_price);
						// let qtty = +value
						// let base = item.price * qtty;
						let grossBase = value - (value * (discountSlab/100));
						let tax =0;
						if(!item.taxSlab && item.original_tax) {
							tax = (grossBase* ((item.original_tax/item.original_base)));
						} else {
							tax =(grossBase * (item.taxSlab / 100));
						}
						
						// item.base = value;
						item.tax = tax;
						item.amount = value+tax;
						// item.original_qtty = qtty;
						item.credit_note_amt = value ? value:0;;
						item.credit_note_qtty = qtty?qtty.toFixed(2):qtty;

						invoiceitems[index] = item;
						setItems(invoiceitems);
				  }}

				  	onChangePrice = {(value,index)=>{
						let invoiceitems = [...items];
						let item = invoiceitems[index];
						let discountSlab = 0;
						let qtty = 0;
						let base = 0;
						let grossBase = base - (base * (discountSlab/100));
						let tax =0;
						if(!item.taxSlab && item.original_tax) {
							tax = (grossBase* ((item.original_tax/item.original_base)));
						} else {
							tax =(grossBase * (item.taxSlab / 100));
						}
						item.tax = tax;
						item.amount = base+tax;
						item.credit_note_qtty = qtty;
						item.credit_note_amt = base;
						item.credit_note_price = value;
						invoiceitems[index] = item;
						setItems(invoiceitems);
					}}
					onChangeTaxSlab={(value, index) => {
						let invoiceItems = [...items];
						let item = invoiceItems[index];
						let discountSlab = 0; // Define discount slab if needed
						let qtty = item.credit_note_qtty ||item.original_qtty|| 0; // Use existing quantity or default to 0
						let base = item.credit_note_price * qtty; // Calculate the base
						let grossBase = base - (base * (discountSlab / 100)); // Calculate the gross base
						// Update taxSlab and recalculate tax
						let taxSlab = +value; // Convert the new taxSlab value to a number
						let tax = grossBase * (taxSlab / 100);
						// Update item properties
						item.taxSlab = taxSlab;
						item.tax = tax;
						item.amount = base + tax; // Recalculate total amount
						item.credit_note_amt = base;
						// Update the items array
						invoiceItems[index] = item;
						setItems(invoiceItems);
					}}
					  onDelete={(item)=>{
						let mItems = [...items];
						mItems = mItems.filter(i=>i.referenceId!==item.referenceId);
						setItems(mItems);
					  }}
					  />
				  }>
			<div className='FadeRight-Effect' style={{fontSize: '12px', width: '100%', minWidth: '400px'}}>
				<div style={{display:'flex',alignItems:'center',marginBottom:35}}>
					<div style={{flex:2}}>
						<div style={{fontWeight: 600, fontSize: '18px', lineHeight: '1.3'}}>Invoice</div>
						<div style={{
							color: 'var(--text-color)',
							fontWeight: 600,
							fontSize: '24px',
							lineHeight: '1.3'
						}}>{details.invoice.counterId}</div>
					</div>
					{details.invoice.files.length?
					<div style={{flex:1,display:'flex',justifyContent:'flex-end'}}>		
						 <div onClick={()=>setAttachmentMode(!attachmentMode)} style={{background:'var(--primary-color)',cursor:'pointer',color:'#fff',padding:'5px 10px',borderRadius:4,...attachmentMode?{background:'#000'}:{}}}>{attachmentMode?'Disable View': 'Image View'} </div>			
					</div>
					:null}
				</div>
				{
					
				attachmentMode ?
				<ViewAttachment items={details.invoice.files}  />
				:
				<>
				<TabSwtich tabs={tabs}/>

				{getTab === 1 ?
					<div id="Invoice_Format" className='FadeRight-Effect' style={{position:'relative',paddingBottom:100}}>

						<FunctionalInput style={{marginTop:20}} type="text" title="Credit Note Number" ref={BILLNUMBER} />
						{/* <div style={{ display: "flex", }}>
                            <DatePickerCustom
                                ref={CN_DATE}
                                label={"Credit Note Date"}
                                formate={"DD-MM-YYYY"}
                            />
                        </div> */}
						{
							details.status==='POHOLD'?
							<div style={{fontSize: '12px',background: '#FDF0DD', borderRadius: '6px', marginTop: '20px', padding: '20px', width: '100%', display: 'flex', alignItems: 'center'}}>
								<svg style={{marginRight:10, fontSize: '16px'}} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
									<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
									<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
								</svg>
								<span>This Invoice is on hold because <b>PO is active or GRN is pending</b>.</span>
							</div>
							:<></>
						}
				
					{
                        details.duplicates && details.duplicates.length  ?
                        <>
                            <div style={{fontSize: '14px', fontWeight: 'bold',margin: '30px 0px 15px 0px'}}>Duplicate Voucher Id</div>
                            {details.duplicates.map((item)=>{
                                    return (
                                        <div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>
                                <svg style={{fontSize: '14px', marginRight: '10px', verticalAlign: 'middle', color: '#fd7e14', marginBottom: '2px'}} className="bi bi-exclamation-triangle-fill" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                                </svg>
                                {item}</span>
                                        </div>
                                    )
                                })}
                        </>
                        : null
                    }

						<div style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>Details
						</div>
						<div style={{marginTop: '5px'}}><span
							style={{color: 'var(--text-color)', marginRight: '3px'}}>Invoice ID:</span>{details.invoice.invoiceId}
						</div>
						<div style={{marginTop: '5px'}}><span
							style={{color: 'var(--text-color)', marginRight: '3px'}}>Category:</span> {details.category}</div>
						<div style={{marginTop: '5px'}}><span style={{
							color: 'var(--text-color)',
							marginRight: '3px'
						}}>Amount:</span> {`INR ${numberWithCommas(details.invoice.amount)}`}</div>

						{
							details.invoice.predictedAmount ?
							<div style={{marginTop: '5px'}}><span style={{
								color: 'var(--text-color)',
								marginRight: '3px'
							}}>Predicted Amount:</span> {`INR ${ details.invoice.predictedAmount?numberWithCommas(details.invoice.predictedAmount.toFixed(2)):'-'}`}</div>
							:null
	
						}

						{
							costAllocationDone ?
							<Allocation
								type="vendor-invoice"
								reference={details.invoice.id}
							/>
							:null
						}
						{
							props.status === 'COMPLETED' || props.status === 'CANCELLED' ?
								<>
									<div style={{
										fontSize: '14px',
										fontWeight: 'bold',
										margin: '30px 0px 15px 0px'
									}}>Information
									</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>Vendor Name:</span> {details.vendor.name}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>Invoice Date:</span> {details.invoice.date}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>Invoice ID:</span> {details.invoice.invoiceId}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>GSTIN:</span> {details.vendor.gstin}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>Email:</span> {details.vendor.email}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>Billing Address:</span> {details.address.address}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>Shipping Address:</span> {details.deliverTo.address}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>GST:</span> {details.taxInformation.gst}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>SGST/UTGST:</span> {details.taxInformation.sgst + details.taxInformation.utgst}
									</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>CGST:</span> {details.taxInformation.cgst}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>IGST:</span> {details.taxInformation.igst}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>TDS:</span> {details.taxInformation.tds}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>Base Amount:</span> {details.taxInformation.baseAmount}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>Payable Amount:</span> {details.taxInformation.paidAmount}</div>
								</>
								: <>
									<div style={{
										fontSize: '14px',
										fontWeight: 'bold',
										margin: '30px 0px 15px 0px'
									}}>Original Invoice Details
									</div>
										


									<FloatInputButton  title='Invoice ID' disabled={details.status !== 'APPROVAL'} placeholder={details.invoice.invoiceId}/>

									<div style={{display: 'flex'}}>
										<div style={{width: '50%'}}><FloatInputButton  title='Base Amount ' placeholder={details.taxInformation.baseAmount} disabled={details.status !== 'APPROVAL'}/>
										</div>
										<div style={{width: '50%', paddingLeft: '10px',marginTop:20}}>
											<Tds id={id} value={details.taxInformation.tds} init={()=>{
												onReload()
											}} 											
											disabled={details.status !== 'APPROVAL'}											
											/>
										</div>
									</div>
									<div style={{display: 'flex'}}>
										<div style={{width: '33%'}}><FloatInputButton  title='SGST / UTGST '
											placeholder={details.taxInformation.sgst?details.taxInformation.sgst.toFixed(2):details.taxInformation.sgst}
											disabled={details.status !== 'APPROVAL'}
											/>
										</div>
										<div style={{width: '33%', paddingLeft: '10px'}}><FloatInputButton
											 title='IGST ' placeholder={details.taxInformation.igst?details.taxInformation.igst.toFixed(2):details.taxInformation.igst} disabled={details.status !== 'APPROVAL'}/></div>
										<div style={{width: '33%', paddingLeft: '10px'}}><FloatInputButton
											title='CGST' placeholder={details.taxInformation.cgst} disabled={details.status !== 'APPROVAL'}/></div>
									</div>

								</>
						}
						<FunctionalInput style={{marginTop:20}} type="number" title="Penalty (optional)" ref={PENALTY} />
						<FunctionalInput style={{marginTop:20}} type="date" title="CREDIT NOTE DATE" ref={DATE} />
						<Form handler={(ref) => (FORM.current = ref)} onChange={(item,val)=> {
							if(item.fieldId === "supply_return_case" && val) setsupply_return_case(val.toLowerCase()) 
						}} creditNote={true} field_Id_To_Hide={"bill_number_supply_return"} tenant={tenant}/>
						{
							items.map(el => {
								return(
									<Form label={`${el.name} custom form`} handler={(ref) => (LINEITEMFORM.current[el.id] = ref)} />
								)
							})
						}
									{supply_return_case && supply_return_case === 'no' && (
										<>
											<div style={{
												fontSize: '14px',
												fontWeight: 'bold',
												margin: '30px 0px 15px 0px'
											}}
											>
												Discount
											</div>
											<div
												style={{
													display: 'flex',
													gap: '10px',
												}}
											>
												<div style={{ flex: 1,marginTop:20 }}>
													<FunctionalInput
														type="number"
														title="Credit Note Base"
														ref={CN_BASE}
														onChange={(e) => {
															let val = e.target.value
															const invoiceBase = details && details.taxInformation && details.taxInformation.baseAmount
															 if(Number(val)>Number(invoiceBase)) {
																CN_BASE.current.value =invoiceBase;
																setCnBase(invoiceBase);
																showToast({
																	type: 'error',
                                                                    message: 'Credit Note Base should not be greater than Invoice Base'
																})
																return
														     }
															setCnBase(e.target.value);
															setOnItemChange(true);
														}}
													/>
												</div>
												<div style={{ flex: 1}}>
													<SelectGST
														defaultValue={cnGst}
														placeholder="Select Gst"
														onSelect={(val) => {
															setEnableGst(true);
															setCnGst(val);
														}}
													/>
												</div>
											</div>
										</>
									)}
						<div
							style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 15px 0px'}}>Attachment
						</div>
						<div style={{
							display: 'flex',
							marginLeft: '-15px',
							flexWrap: 'wrap',
							maxHeight: '250px',
							overflowY: 'scroll'
						}}>

							<FileUpload readOnly={readOnly} onUpload={uploadFile}/>
							{
								files.length > 0 ?
									files.map((item, index) => {
										return (
											<PhotoTile  key={`Photo-${index}`} link={item}/>
										)
									}) : <></>
							}
						</div>
						
						{
							<div style={{left:'calc(100% - 650px)',right:0,bottom:0,background:'#fff',borderTop:'1px solid var(--border-color)',position:'fixed',height:55,width:'100%',display:'flex',alignItems:'center',paddingLeft:10,paddingRight:10}}>
								{btnLoading ?
								<div
									className="btn btn-portlet-sucess" style={{background:"#E4EFE7",color:'#198754',width:'100%',maxWidth:'620px'}}>
									<StateButton />
								</div>:
									<div onClick={()=>{
										
										let mItems = [...items];
										let priceFlag = false;
										mItems.forEach((item)=>{
											if(Number(item.credit_note_price) === 0){
												priceFlag = true;
											}
										})
										if(creditDebitNoteRateEdit && priceFlag){
											showToast({
												typr:"error",
												message:"Price of lineItems can not be 0"
											})
											return;
										}
										if(files && files.length===0){
											showToast({
												typr:"error",
												message:"Attach atleast one file"
											})
											return;
										}
										let flag = false;
										mItems.forEach((item)=>{
											if(Number(item.credit_note_amt) === 0 || Number(item.credit_note_qtty === 0)){
												flag = true;
											}
										})
										if(flag){
											showToast({
												type:"error",
												message:"Amount of line items can't be 0"
											})
											return;
										}
										let penalty = PENALTY.current.value;
										let cnNumber = BILLNUMBER.current.value;
										let date = DATE.current.value
										// let cnDate = CN_DATE.current.value;
										if(!cnNumber){
											showToast({
												type:"error",
												message:"Please enter credit note number"
											})
											return;
										}

										if(!date) {
											showToast({
												type:'error',
												message:'Please enter the credit note date'
											})
											return;
										}

										if(files.length===0) {
											showToast({
												type:'error',
												message:"Please attach atleast 1 file"
											})
											return
										}
										// if(!cnDate){
										// 	showToast({
										// 		type:"error",
										// 		message:"Please enter credit note date"
										// 	})
										// 	return;
										// }
										// cnDate = Moment(cnDate, "DD-MM-YYYY").valueOf();
										//credit date 
										if(penalty) {
											penalty=+penalty;
										} else  {
											penalty = 0;
										}

										if(!mItems.length) {
											showToast({
												type:"error",
												message:"Atleast one item is required"
											})
											return;
										}

										let tax = 0;
										let total = 0;
										mItems.forEach(item=>{
											tax = tax + parseFloat(item.tax);
											total += parseFloat(item.amount)
										})
										total -= tax; // calculate new base amount
										let tds = (parseFloat(details.taxInformation.tds)/parseFloat(details.taxInformation.baseAmount))*(total);
										let data = {
											invoiceId:details.invoice.id,
											vendorId:details.vendor.id,
											attachments:[...files],
											tax,
											penalty,
											roundOff:0,
											billNumber: cnNumber ? cnNumber : '',
											discount:0,
											mismatch:false,
											tds,
											tdsSlab:0,
											currency:details.invoice.currency,
											forms:{},
											lineItems:mItems.map(item=>{
												let baseAmount = item.amount - item.tax;
                								let tdsAmount = parseFloat((baseAmount)*(item.tdsRate/100))

												return({
													id:item.id,
													service:{
														label:item.service
													},
													productId:item.productId,
													itemId:item.referenceId,
													name:item.name,
													price:(creditDebitNoteRateEdit ? item.credit_note_price: item.price),
													hsn:item.hsn,
													qtty:+item.credit_note_qtty,
													unit:item.unit,
													glCode:"",
													notes:"",
													discount:item.discount,
													tds:item.tds,
													base:item.credit_note_amt,
													total:item.amount,
													grossBase:item.gross_amt,
													cessAmount:item.cessAmount,
													cessPercentage:item.cessPercentage,
													tax:item.tax,
													taxSlab:item.taxSlab,
													forms:{
	
													},
													data:item.attrs,
													tdsAmount,
													tdsCode:item.tdsCode,
													tdsDesc:item.tdsDesc,
													tdsRate:item.tdsRate
												})
											})
										};
										if (lineItemCustomForm.length) {
											let lineItemFormCnt = 0;
											let lineitems = data.lineItems;
											const updateLineItemForms = (elId, cb) => {
												lineItemFormCnt++;
												lineitems = lineitems.map(item => {
													if (item.id === elId) {
														return { ...item, forms: cb };
													}
													return item;
												});
											};
											for (let [index, el] of mItems.entries()) {
												LINEITEMFORM.current[el.id].getKeyValue(cb => {
													updateLineItemForms(el.id, cb); 
												});
										
												if (lineItemFormCnt !== index + 1) {
													break;
												}
											}
											data.lineItems = lineitems;									
											if (lineItemFormCnt !== mItems.length) {
												return;
											}
										}
										if(DATE.current.value){
											data['date'] = moment(DATE.current.value).valueOf()
										}
										FORM.current.getKeyValue(cb=>{
											data.forms = cb;
											setbtnLoadin(true);
											post(`vendor/vendor/creditNote/creditNote`,{data},(e,r)=>{
												if(r) {
													showToast({
														type:'success',
														message:'Credit Note Generate Successfully',
													})
													props.init();
												} else {
													setbtnLoadin(false);
													showToast({
														type:'error',
														message:e.data.message
													})
												}
											})
										})

								}}  className='btn btn-portlet-success' style={{background:"#E4EFE7",color:'#198754',width:'100%',maxWidth:'620px'}}>
								   Generate Credit Note
								</div>}
							</div>
								
						}
                      
                       
						
					</div> : <></>
				}

				{getTab === 2 ?
					<>
						<div id="Invoice_Insight" className='FadeRight-Effect'>
							<div
								style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>Insights
							</div>
							{
								details.tags ? Object.entries(details.tags).map(renderTags) : <></>
							}

							{
								details.invoice.items.filter(i=>i.data.length>0).map(mapLineItemTag)
							}
							<div
								style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>View
							</div>
							{
								details.views && details.views.map(mapView)
							}
						</div>
					</>
					: <></>
				}

				{getTab === 3 ?
					<div id="Invoice_Timeline" className='FadeRight-Effect'>
						<div style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>Timeline
						</div>
						<Track stages={
							[...details.timelines.map((log,index) => {

								let DESC = <div><b>{log.name}</b><br/>
									<div style={{marginTop: '5px'}}>Type: {log.type}</div>
									<div style={{marginTop: '5px'}}>Status: {log.status}</div>

									<div style={{marginTop: '5px'}}>Sent
										on: {Moment(log.time).format("hh:mm a DD MMM YY")}
										<br/>{log.actionTime && log.status !== 'PENDING' ? `Approved on: ${Moment(log.actionTime).format(" hh:mm a DD MMM YY")}` : ''}
									</div>
								</div>

								return (
									{
										title: log.email,
										desc: DESC,
										status: log.type === 'start' || log.type === 'scanner-end' || (log.type === 'scanner-start' && details.timelines.length>(index+1))? true : log.status ? timelineLogStatus.includes(log.status) : log.name === 'Finance Action' || log.type === 'Completed' ? true : false
									})
							})]
						}/>
					</div> : <></>
				}
				{
					getTab === 4 ?
					<div id =  "Settlements" className="FadeRight-Effect">
						{
							Settlements?
							<div style={{marginTop:30}}>
								<KeyValue title="LedgerId" value={Settlements.ledgerId} />
								<KeyValue title="Created At" value={Moment(Settlements.createdAt).format('DD-MM-YYYY')} />
								<KeyValue title="Currency" value={Settlements.currency} />


							</div>
							:null
						}
						{
						Settlements?
						Settlements.settlements.length?
						<div className='FadeRight-Effect'>
							<div style={{marginTop:30}}><span style={{color: '#666', marginRight: '3px'}}>Ledger ID: </span>{Settlements.ledgerId}</div>
							<div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Amount: </span>{Settlements.currency} {Settlements.amount}</div>
							<div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Created On: </span>{moment(Settlements.createdAt).format('HH:MM a DD-MM-YYYY')}</div>
							<div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Remarks: </span>{Settlements.attrs.remarks}</div>
							<div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Virtual Payout: </span>{Settlements.attrs['virtual.payout']}</div>
							<div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Reference: </span>{Settlements.attrs.reference}</div>

							<div style={{fontSize: '14px', fontWeight: 'bold',margin: '30px 0px 10px 0px', marginBottom: '20px'}}>Settlements</div>
							{
								Settlements.settlements.length?
								Settlements.settlements.map((item, index)=>{
									return (
										<div style={{padding: '10px 20px', border: '1px solid var(--border-color)',position:'relative', borderRadius: '6px', marginTop: '15px'}}>
											<div key={`info-${index}`} style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Created On: </span>{moment(item.createdAt).format('HH:MM a DD-MM-YYYY')}</div>
											<div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Amount: </span>{item.currency} {item.amount}</div>
											<div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Ledger ID: </span>{item.ledgerId}</div>
											<div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Type: </span>{item.type}</div>
											{
												item.type==='INVOICES' || item.type==='POADVANCE'?
													<>
													<div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>TDS Amount: </span>{item.tdsAmount}</div>
													<div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Paid Amount: </span>{item.paidAmount}</div>
													</>
													:<></>
											}
											<div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Transferred: </span>{item.transferred?'Yes':'No'}</div>

											<div style={{position:'absolute',cursor:'pointer',right:10,top:10,color:'var(--red-color)'}}>
												{item.type==='PARTIAL' && !item.transferred ?
												 <div onClick={()=>{setRecallPartial(item)}}>Recall</div>
												 :null
												}
											</div>
										</div>
									)
								})
								:<div style={{padding: '20px', border: '1px solid var(--border-color)', borderRadius: '6px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>No Information available</div>
							}
						</div>
						:<div style={{padding: '20px', border: '1px solid var(--border-color)',marginTop:20, borderRadius: '6px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>No Information available</div>
						:<Spinner/>
						}
						
						{
							Settlements && Settlements.advances && Settlements.advances.length ?
							<>
								<div style={{fontSize: '14px',marginBottom:20, fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>Select Advance to settled this invoice</div>
								<NormalTable
									headers={[
										{
											key: "code",
											name: "Details",
											weight: 3,
										},
										{
											key: "amount",
											name: "Amount",
											weight: 2,
										},

									]}
                    				data={Settlements.advances.filter(val=>val.transferred)}
                   					mapItem={(item,index)=>{
										   let active = item.ledgerId === advance;
										   return(
											   <div key={index} className="border-bottom fintrip-department-item table-item">
												   	<div className="linear-layout horizontal">
														   <div style={{flex:3}}>
														   		<div style={{
																	   display:'flex',
																	   alignItems:'center'
																   }}>
																	<div onClick={()=>{
																		setAdvance(item.ledgerId);
																	}} style={{height:15,cursor:'pointer',width:15,border:'1px solid var(--border-color)',...active?{background:'var(--success-color)',borderColor:'var(--success-color)'}:{borderColor:'var(--border-color)'}}} />
																	<div style={{marginLeft:10}}>
																		<p onClick={(e)=>{
																			e.stopPropagation();
																			setLedger(item.ledgerId);
																		}} className="top" style={{fontSize:14,cursor:'pointer'}}>{item.ledgerId}</p>
																		<p className="bottom" style={{fontSize:12,marginTop:5}}>Type : {item.type}</p>
																	</div>
																</div>
														   </div>
														   <div style={{flex:2}}>
															   {item.currency} {item.amount}
															</div>
													</div>
												</div>
										   )
									   }}
                				/>
								{advance ?
								<p style={{
								color:'var(--white-color)',
								fontSize:14,
								background:'var(--primary-color)',
								padding:"8px 20px",
								borderRadius:2,
								cursor:'pointer',
								width:150,
								textAlign:'center',
								marginTop:20
							}} onClick={()=>{
								post(`settlements/settlements/${advance}/invoice/${id}/tag`,{},(e,r)=>{
									if(r) {
										props.onReload();
										fetchSettlements(id);
										showToast({
											type:'success',
											message:"Advance settled successfully against invoice"
										})
									} else {
										showToast({
											type:'error',
											message:e.data.message
										})
									}
								})
							}}>Settle Invoice </p>
								:null
								}
							</>
							:null
						}

						{
							details.status === 'COMPLETED' && Settlements && !Settlements.settled?
							(integrationData && _.size(integrationData) > 0 && integrationData['postingSynced'])?null:<div style={{marginTop:10}}>

							<p style={{
								color:'var(--white-color)',
								fontSize:14,
								background:'var(--red-color)',
								padding:"5px 12px",
								borderRadius:4,
								cursor:'pointer',
								position:'absolute',
								right:100,
								top:30
							}} onClick={()=>{setRecall(true);}}>
								Recall Invoice
							</p>
							</div>
							:
							null
						}


					</div>:
					null
				}
				{
					getTab===5?
					<div id =  "Po" className="FadeRight-Effect">
						<PODetails id={props.details.po.id}/>
					</div>:undefined
				}
				{
					getTab===6 ?
					<div className='FadeRight-Effect'>
						<SupportTicket id={id} type="vendor-invoice" />
					</div>
					:null
				}
				{
					getTab===7 ?
					<>
					{
						integrationData && Object.keys(integrationData).length ?
						   <Integration  
						   		type="vendor-invoice"
								reference={details.invoice.id}
								integrationData={integrationData}
								refresh={props.onReload}
								/>

						   :null
					}
					</>
					:null
				}
				
				
				<div style={{height: '50px'}}/>
				</>}
			</div>
			

			<Modal
                title='Add Partial Payment'
                des="Fill up the details"
                close={()=>{
                    setPartial(false)
                }}
                show={partial}
                onSave={()=>{
                    let amount = +AMOUNT.current.value;
                    let remarks = REMARK.current.value;

                 
                    if(!amount) {
                        showToast({
                            type:'error',
                            message:'Please enter the amount'
                        })
                        return
                    }
                    if(!remarks) {
                        showToast({
                            type:'error',
                            message:"Please enter the remarks"
                        })
                        return;
                    }
				
                  
                    let data = {
                        amount,
                        remarks,
                        currency:details.invoice.currency,

                    }

					post(`settlements/settlements/invoice/${id}/partial`,{...data},(e,r)=>{
                        if(r) {
                            setPartial(false)
                            props.onReload()
							fetchSettlements(id)
                            showToast({
                                type:'success',
                                message:"Partial payment done successfully"
                            })
                        } else {
							handleError(e)
						}
                    })

                }}
            >
                <FunctionalInput type="text" title="Amount" ref={AMOUNT} />
				 <FunctionalInput type="text" title="Remarks" ref={REMARK} />

            </Modal>

			<Modal
                title='Recall Invoice'
                des="Enter a reason to recall it"
                close={()=>{
                    setRecall(false)
                }}
                show={recall}
                onSave={()=>{
                    let remarks = REMARKS1.current.value;

                    if(!remarks) {
                        showToast({
                            type:'error',
                            message:"Please enter the remarks"
                        })
                        return;
                    }


                    let data = {
                        remarks
                    }

					post(`settlements/vendor/invoices/${id}/recall`,{...data},(e,r)=>{
                        if(r) {
                            setRecall(false)
							fetchSettlements(id)
                            props.onReload()
							setTab(1)
                            showToast({
                                type:'success',
                                message:"Invoice Recall  successfully"
                            })
                        } else {
							handleError(e)
						}
                    })

                }}
            >
                <FunctionalInput type="text" title="Remarks" ref={REMARKS1} />

            </Modal>

			<Modal
                title='Recall Partial Payment'
                des="Enter a reason to recall it"
                close={()=>{
                    setRecallPartial(false)
                }}
                show={recallPartial}
                onSave={()=>{
                    let remarks = REMARKS.current.value;

                    if(!remarks) {
                        showToast({
                            type:'error',
                            message:"Please enter the remarks"
                        })
                        return;
                    }


                    let data = {
                        remarks,
                    }

					post(`settlements/settlements/${recallPartial.id}/cancel`,{...data},(e,r)=>{
                        if(r) {
                            setRecallPartial(false)
							fetchSettlements(id)
                            props.onReload()
							setTab(1)
                            showToast({
                                type:'success',
                                message:"Partial Payment Recall  successfully"
                            })
                        } else {
							handleError(e)
						}
                    })

                }}
            >
                <FunctionalInput type="text" title="Remarks" ref={REMARKS} />

            </Modal>

			{
				ledger ?
				<ViewLedger
					id={ledger}
					onClose={()=>{
						setLedger(false)
					}}
				/>
				:null
			}

			{
				decline && decline!== 0 ? <DropdownModal
					title="Decline Invoice"
					button="Decline"
					close={() => {
						setDecline(undefined)
					}}
					dropdown="vendor-decline"
					onNext={val => {
						axios.delete(`${getBaseUrl()}/settlements/vendor/invoices/${decline}?remarks=${val}`, {
							headers: {
								"content-type": "text/html"
							},
						}).then(s => {
							showToast({
								type: 'success',
								message: 'Invoice declined successfully'
							})
							setDecline(undefined)
							props.onReload();
						}).catch(handleError)
					}}
				/> : null
			}


		</SidePane>
	)

}
